/* eslint-disable camelcase */
import * as React from 'react';
import * as yup from 'yup';

import {
  useForm,
  FormProvider,
  Controller,
  useFieldArray,
} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useLocation} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import _, {set} from 'lodash';


import CustomSkeleton from 'components/common/CustomSkeleton';
import {
  TextField as TextFieldCustom,
  SelectField,
  CustomColorGradientField,
} from 'components/common/ReactHooksFormFields';
import {useTranslation} from 'components/providers/TranslationProvider';
import useDataSetColumns from 'api/hooks/useDataSetColumns';
import Modal from 'components/common/Modal';
import {setWidget, updateWidget} from 'store/appSlice';
import Toast from 'components/common/Toast';
import useDataSetColumnValues from 'api/hooks/useDataSetColumnValues';
import useRandomId from 'components/hooks/useRandomId';

import SelectWidgetType from './SelectWidgetType';
import Histogram from './histogram/Histogram';
import Category from './category/Category';
import List from './list/List';
import Stat from './stat/Stat';
import useDataSetsOrg from '../../../../../../../api/hooks/useDataSetsOrg';
import {
  giveMeMapId, giveMeId, makeid
} from '../../../../../../../utils/supportComponents';
import {useAuth} from '../../../../../../providers/AuthProvider';
import useGeneratePotentialOffers from 'api/hooks/useGeneratePotentialOffers';
import axios from "../../../../../../../api/axios/axiosInstance";
import apiPaths from "../../../../../../../api/apiPaths";
import {status200} from "../../../../../../../api/status.utils";
import { messageSeverity, processJobStatus } from 'utils/constants';
import {ReactSVG} from 'react-svg';
import {
  IconButton,
  InputAdornment,
  TextField,
  Box,
  Typography,
  StepLabel,
  Stepper,
  Step,
  ToggleButton,
  MenuItem,
  Divider,
  Grid, Paper
} from '@mui/material';
import useDataQueryDrop from 'api/hooks/useDataQueryDrop';
import {FormulaGenerator} from './formula/FormulaGenerator';
import usePolygonDataSetColumns from 'api/hooks/usePolygonDataSetColumns';
import {CaseGenerator} from './formula/CaseGenerator';
import useDefaultGradients from 'api/hooks/useDefaultGradients';
import Parameters from "./parameters/Parameters";
import CustomInputColorGradient
  from '../../../../../../common/CustomInputColorGradient';
import {
  adornmentColorStyle,
  adornmentPercentStyle,
  commonStyle,
  iconButtonStyle, inputAreaStyle,
  inputColorCommonStyle,
  inputLimitStyle, inputStyle,
  labelInputStyle,
  MuiBoxToggle,
  MuiBtnAction,
  MuiBtnClose,
  MuiCalculatorDisabled, MuiContainerLegendItems,
  MuiContainerSpecificInfo, MuiCustomSelectField, MuiCustomStepIcon,
  MuiGridCategory,
  MuiGridCategoryContainer, MuiGridElement,
  MuiGridElementContainer,
  MuiGridFormula,
  MuiIconButtonCommon, MuiSlider, MuiStepDescription,
  MuiSwitchField,
  MuiToggleButtonGroup,
  MuiTypography,
  MuiTypographyCategory,
  MuiTypographyCategoryList,
  MuiTypographyCommon,
  MuiTypographyInfo,
  MuiTypographyLimit,
  MuiTypographySortBy,
  MuiTypographyToggle,
  MuiTypographyVisuality,
  MuiVisuality, selectSkeleton,
  stepLabelStyle, stepperStyle
} from './styles/widgetForm';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CalculatorLegend from './formula/CalculatorLegend';
import { InfoOutlined } from '@mui/icons-material';
import {
  inputColorFieldStyle,
  textFieldCommonStyle
} from '../../layer/form/styles/layerForm';
import TransferListSort from '../../../../common/TransferListSort';
import { ButtonPanel } from './formula/ButtonPanel';
import { iconsName } from '../../../mapsHandlers/layer/form/iconsName';

import Loader from "../../../../common/Loader";
import { MuiRefreshIcon, validateCaseStyle } from './styles/caseGenerator';
import RecommendationsDemand from './recommendations/RecommendationsDemand';
import RecommendationsServicePoint from './recommendations/RecommendationsServicePoint';
import RecommendationsVisualization from './recommendations/RecommendationsVisualization';
import RecommendationsGlossary from './recommendations/RecommendationsGlossary';
import TypographyWithInfoIconTooltip
  from '../../../../../../common/TypographyWithInfoIconTooltip';

const WidgetForm = ({action, open, onClose, groupId}) => {

  const {t,lng} = useTranslation();
  const location = useLocation();
  const widget = location.state;
  const [mapId,] = React.useState(widget ? giveMeMapId(location.pathname) : location.search.split('&')[2]?.split('=')[1]);
  const dispatch = useDispatch();
  const [randomId,] = React.useState(widget ? widget.id : useRandomId());
  const {data: gradients, isSuccess: gradientIsSuccess} = useDefaultGradients();
  const layers = useSelector((state) => state.app.layers);
  const widgets = useSelector((state) =>
    state.app.widgets.filter((g) => g.default_tab === groupId)
  );
  const definedParametersCatalog=widgets.find(w=>w.type==='parameters')?.params?.parametersCatalog;
  const [error,] = React.useState('');
  const [messageToast, setMessageToast] = React.useState('');
  const [openToast, setOpenToast] = React.useState(false);

  const [gradient, setGradient] = React.useState({});
  const [activeColors, setActiveColors] = React.useState([]);

  const [columnsListOrder, setColumnListOrder] = React.useState([]);
  const [, setColumnListOrderFormula] = React.useState([]);
  const [caseCategories, setCaseCategories] = React.useState([])

  const [columnsListOrderPoly, setColumnsListOrderPoly] = React.useState([]);
  const [columnsListOrderStats, setColumnListOrderStats] = React.useState([]);

  const [activeStep, setActiveStep] = React.useState(0);
  const [disableButtonStep, setDisableButtonStep] = React.useState(false);

  const [, setSelectLayer] = React.useState('');

  const [palette_reverse, setPaletteReverse] = React.useState(
    (widget && widget.params && widget.params?.palette_reverse) || false
  );
  const [colorInMap, setColorInMap] = React.useState(
    action === 'update' ? widget?.params?.colorInMap === undefined ? true : widget.params?.colorInMap : true
  )
  const [selectDataset, setSelectDataset] = React.useState(action === 'update' &&
  widget?.type === 'polygon' ? widget?.params?.dataset : '');
  const [circuchroneTable, setCircuchroneTable] = React.useState(action === 'update' &&
  widget?.type === 'polygon' ? widget?.params?.circuchroneTable : '');

  const [offerCoverageRadius, setOfferCoverageRadius] = React.useState('update' && widget?.params?.offerCoverageRadius ? widget?.params?.offerCoverageRadius : 200);
  const [progress, setProgress] = React.useState(0);
  const [, setShowProgressBar] = React.useState(false);
  const [privateLoading, setPrivateLoading] = React.useState(false);
  const [notificationMessage, setNotificationMessage] = React.useState('');
  const [severity, setSeverity] = React.useState('error');
  const iconCustomDefault = 'marker.svg';
  const [, setCategoryOrder] = React.useState('alphabetical')
  const [isFormulaFieldSelected, setIsFormulaFieldSelected] = React.useState('update' ? widget?.params?.isFormulaFieldSelected : false);
  const [formulaWithAlias, setFormulaWithAlias] = React.useState([])
  const [, setValidFormula] = React.useState(false)
  const [parameterCatalog, setParameterCatalog] = React.useState(widget?.params?.parametersCatalog||[]);
  const recommendationsGeneratedSuccessfully = React.useRef(false);
  const [widgetInputNameStyle, setWidgetInputNameStyle] = React.useState({
    height: 35,
    paddingBottom: 23,
  });
  const [widgetProcessJobStatus, setWidgetProcessJobStatus] = React.useState(processJobStatus.WAITING);
  const [wasRecommendationsFormUpdated, setWasRecommendationsFormUpdated] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const [isWidgetFormBtnDisabled, setIsWidgetFormBtnDisabled] = React.useState(true);
  const [orderByValue, setOrderByValue] = React.useState('alphabetical');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isTargetDemandsFieldSelected, setIsTargetDemandsFieldSelected] = React.useState( action === 'update' ? widget?.params?.isTargetDemandsFieldSelected || (widget?.params?.targetDemands !== '' && widget?.params?.targetDemands !== undefined && widget?.params?.targetDemands !== null): false);
  const [polygonLimitValue, setPolygonLimitValue] = React.useState(500);
  const [, setChangeDetectedShow] = React.useState(false)
  const [, setChangeDetectedOrder] = React.useState(false)
  const [, setChangeDetectedName] = React.useState(false)
  const [orderColumn, setOrderColumn] = React.useState([]);
  const [listColumns, setListColumns] = React.useState([]);
  const [goCheckCaseStructure, setGoCheckCaseStructure] = React.useState(false)
  const [disabledContinue, setDisabledContinue] = React.useState(true)
  const [isFormulaValidate, setIsFormulaValidate] = React.useState(false);
  const [isTargetDemandFormulaValidate, setIsTargetDemandFormulaValidate] = React.useState( isTargetDemandsFieldSelected )
  const [validatingStructureCase, setValidatingStructureCase] = React.useState(false);
  const [isCaseGenerationValid, setIsCaseGenerationValid] = React.useState(false);

  const openPopover = Boolean(anchorEl);
  const {
    mutateAsync: generatePotentialOffers,
    data: potentialOffersData,
    status: potentialOffersDataStatus,
  } = useGeneratePotentialOffers();

  const {
    mutateAsync: dropDatasetTableByName,
    data: dropDatasetTableByNameData,
    status: dropDatasetTableByNameStatus,
    isSuccess: dropDatasetTableByNameIsSuccess,
    isLoading: dropDatasetTableByNameIsLoading,
  } = useDataQueryDrop();

  const defaultIcon = iconsName.find(i => i.name === 'marker')?.name;

  const initialSteps = [
    {
      label: t('widget_types_label'),
      description: t('widget_types_decription'),
    },
    {
      label: t('widget_general_data_label'),
      description: t('widget_general_data_description'),
    },
    {
      label: t('widget_specific_data_label'),
      description: t('widget_specific_data_description')
    },
  ];

  const [steps, setSteps] = React.useState(initialSteps);
  const [formulaErrors, setFormulaErrors] = React.useState({});
  const [formula, setFormula] = React.useState([ {id: 1, formula: ''} ]);
  const [targetDemandsFormula, setTargetDemandsFormula] = React.useState([ {id: 1, formula: ''} ]);

  const convertOldSavedOpacityValuesToPercentage = ( number ) => {
    if ( number > 1  ) {
      return number
    } else {
      return number * 100;
    }
  }

  const getDefaultValues = () => {

    if(widget?.type === 'statistics'){
      widget.params.fields = widget.params.fields.map((field) => {
        if (!field.id) {
          field.id = Date.now().toString(36) + Math.random().toString(36)
        }
        return field;
      });
    }

    return widget
      ? {
        name: widget.name,
        layer: widget.layer,
        description: widget.description,
        type: widget.type,
        datasetColumn: widget.params.field,
        calculator: widget.params.calculator,
        order: widget.params.order || '',
        alias: widget.params.alias,
        gradientId: widget.gradientId,
        colorType: widget.params?.colorType || '',
        categoryColor: widget.params?.categoryColor || '',
        colorInMap: widget.params?.colorInMap || true,
        operation: widget.params.operation,
        palette_reverse: widget.params.palette_reverse,
        customCategoryColors: widget.params?.customCategoryColors || [],
        customHistogramColors: widget.params?.customHistogramColors?.map((c) => {
          return {
            bucket: c.bucket,
            color: c.color,
          };
        }),
        parametersCatalog:widget.type==='parameters'?widget?.params?.parametersCatalog||[]:[],
        limit: widget.type === 'polygon' ? widget.polygonLimit : 0,
        datasetName: widget.type === 'polygon' ? widget.dataSet ? widget.dataSet.cartoName : '' : '',
        circuchroneTable: widget.type === 'polygon' ? widget.circuchroneTable ? widget.circuchroneTable.cartoName : '' : '',
        demandDatasetName: widget.params.demandDatasetName,
        densityVariable: widget?.params?.densityVariable || '',
        welfareVariable: widget?.params?.welfareVariable || '',
        formulaWelfare: widget?.params?.formulaWelfare || '',
        isFormulaFieldSelected: widget?.params?.isFormulaFieldSelected || false,
        offerCoverageRadius: widget?.params?.offerCoverageRadius || 200,
        offerCapacity: widget?.params?.offerCapacity || 1,
        offerCost: widget?.params?.offerCost || 1,
        currentOfferDatasetName: widget?.params?.currentOfferDatasetName || '',
        currentOfferLayerName: widget?.params?.currentOfferLayerName || '',
        capacityVariable: widget?.params?.capacityVariable || '',
        layerColor: widget?.params?.layerColor || '',
        layerBorderColor: widget.type === 'polygon' ? widget?.params?.layerBorderColor || widget?.params?.layerColor : '',
        layerOpacity: widget.type === 'polygon' ? widget?.params?.layerOpacity || 0.25 : 0,
        layerBorderWidth: widget.type === 'polygon' ? widget?.params?.layerBorderWidth || 1 : 0,
        layerIsochroneColor: widget?.params?.layerIsochroneColor || '',
        layerRecommendationColor: widget?.params?.layerRecommendationColor || '',
        isOfferCapacitySelected: widget?.params?.isOfferCapacitySelected || false,
        targetDemands: widget?.params?.targetDemands || '',
        isOfferSelected: widget?.params?.isOfferSelected,
        isTargetDemandsFieldSelected: widget?.params?.isTargetDemandsFieldSelected || false,

        //  layers Configuration

        recommendedPointLayerOpacity: widget?.params?.layersConfiguration?.recommendedPointLayerOpacity && convertOldSavedOpacityValuesToPercentage(
          widget?.params?.layersConfiguration?.recommendedPointLayerOpacity
        ) || 1,
        recommendedPointLayerName: widget?.params?.layersConfiguration?.recommendedPointLayerName || t('recommendations_point_layer'),
        recommendedPointLayerColor: widget?.params?.layersConfiguration?.recommendedPointLayerColor || '#d320d6',
        recommendedPointLayerIcon: widget?.params?.layersConfiguration?.recommendedPointLayerIcon || iconCustomDefault,
        recommendedPointLayerVisibility: true,

        recommendedAreaLayerOpacity: widget?.params?.layersConfiguration?.recommendedAreaLayerOpacity && convertOldSavedOpacityValuesToPercentage(
          widget?.params?.layersConfiguration?.recommendedAreaLayerOpacity
        ) || 25,
        recommendedAreaLayerColor: widget?.params?.layersConfiguration?.recommendedAreaLayerColor || '#1bd041',
        recommendedAreaLayerVisibility: widget?.params?.layersConfiguration?.recommendedAreaLayerVisibility,

        currentOfferAreaLayerOpacity: widget?.params?.layersConfiguration?.currentOfferAreaLayerOpacity && convertOldSavedOpacityValuesToPercentage(
          widget?.params?.layersConfiguration?.currentOfferAreaLayerOpacity
        ) || 25,
        currentOfferAreaLayerName: widget?.params?.layersConfiguration?.currentOfferAreaLayerName || t('current_offers_area_layer'),
        currentOfferAreaLayerColor: widget?.params?.layersConfiguration?.currentOfferAreaLayerColor || '#ff0313',
        currentOfferAreaLayerVisibility: widget?.params?.layersConfiguration?.currentOfferAreaLayerVisibility,

        isGlobal: (widget?.params?.isGlobal === null || widget?.params?.isGlobal === undefined) ? true : !!widget?.params?.isGlobal,
        userOwner: widget?.params?.userOwner || '',
        allowsRegenerateServicePoint: (widget?.params?.allowsRegenerateServicePoint === null || widget?.params?.allowsRegenerateServicePoint === undefined) ? true : !!widget?.params?.allowsRegenerateServicePoint,
        potentialOffersDataset: widget?.params?.potentialOffersDataset || '',
        isPotentialOffersDatasetSelected: !!widget?.params?.potentialOffersDataset,

        // custom labels

        coverageRadiusLabel: widget?.params?.customLabels?.coverageRadius || '',
        costLabel: widget?.params?.customLabels?.cost || '',
        recommendationsOfferCapacityLabel: widget?.params?.customLabels?.recommendationsOfferCapacity || '',
        recommendationsBudgetLabel: widget?.params?.customLabels?.recommendationsBudget || '',
        recommendationsExpansionCapacityLabel: widget?.params?.customLabels?.recommendationsExpansionCapacity || '',
        recommendationsExpansionCostLabel: widget?.params?.customLabels?.recommendationsExpansionCost || '',
        recommendationsDemandDensityLabel: widget?.params?.customLabels?.recommendationsDemandDensity || '',
        variableWelfareLabel: widget?.params?.customLabels?.variableWelfare || '',
        recommendationsFormulaPriorizationVariableLabel: widget?.params?.customLabels?.recommendationsFormulaPriorizationVariable || '',
        
        polygonDisplayCol: widget?.params?.polygonDisplayCol || '',
        
        isCostVisible: !!widget?.params?.fieldsToShow?.isCostVisible,
        isOfferCapacityVisible: !!widget?.params?.fieldsToShow?.isOfferCapacityVisible,
        isExpansionCapacityVisible: !!widget?.params?.fieldsToShow?.isExpansionCapacityVisible,
        isExpansionCostVisible: !!widget?.params?.fieldsToShow?.isExpansionCostVisible,
      }
      : {
        categoryColor: localStorage.getItem('colorPrimary'),
        colorType: 'simple',
        gradientId: '',
        operation: 'COUNT',
        description: '',
        layer: '',
        calculator: false,
        alias: '',
      };
  };

  const schema = yup.object().shape({
    layer: yup.string().when('type', {
      is: (val) => val === 'polygon'||val==='parameters',
      then: yup.string().notRequired(),
      otherwise: yup.string().required('required'),
    }),
    name: yup.string().min(4, 'min_4_characters').max(80, 'max_80_characters').required('required'),
    type: yup.string().required('required'),
    description: yup.string().max(800, 'max_80_characters').notRequired(),

    datasetColumn: yup.string()
      .test('dataset-column-required', t('required'), (value, context) => {
        const val = context.parent.type;
        const isCalculatorEnabled = context.parent.calculator;
        const datasetColumn = value;
        if ((val === 'list' || val === 'statistics' || val === 'histogram' || val === 'category') && isCalculatorEnabled && !datasetColumn) {
          return false;
        }
        if (val === 'recommendations' || val === 'polygon') {
          return true;
        }
        if ((val === 'histogram' || val === 'category') && !datasetColumn) {
          return false;
        }
        return true;
      }),

    gradientId: yup.string().notRequired(),
    alias: yup.string().notRequired(),
    calculator: yup.boolean().notRequired(),
    palette_reverse: yup.boolean().notRequired(),

    colorType: yup.string().when('type', {
      is: (val) => val === 'category',
      then: yup.string().required('required'),
      otherwise: yup.string().notRequired(),
    }),

    categoryColor: yup.string().when('colorType', {
      is: (val) => val === 'simple',
      then: yup.string().required('required'),
      otherwise: yup.string().notRequired(),
    }),
    layerColor: yup.string(),
    layerBorderColor: yup.string(),
    layerOpacity: yup.number(),
    layerBorderWidth: yup.number(),
    layerRecommendationColor: yup.string(),
    layerIsochroneColor: yup.string(),

    customCategoryColors: yup.array().when('type', {
      is: (val) => val === 'category',
      then: yup.array().of(
        yup.object().shape({
          color: yup.string().default('#2470C2').required('required'),
          value: yup.string().required('required'),
        })
      ),
      otherwise: yup.array().of(
        yup.object().shape({
          color: yup.string().notRequired(),
          value: yup.string().notRequired(),
        })
      ),
    }),
    demandDatasetName: yup.string().when('type', {
      is: (val) => val === 'recommendations',
      then: yup.string().required('required'),
      otherwise: yup.string().notRequired(),
    }),
    densityVariable: yup.string().when('type', {
      is: (val) => val === 'recommendations',
      then: yup.string().required('required'),
      otherwise: yup.string().notRequired(),
    }),
    welfareVariable: yup.string().when('type', {
      is: (val) => val === 'recommendations' && !isFormulaFieldSelected,
      then: yup.string().required('required'),
      otherwise: yup.string().notRequired(),
    }),
    order: yup.string().when('type', {
      is: (val) => val === 'category',
      then: yup.string().required('required'),
      otherwise: yup.string().notRequired(),

    }),
    colorInMap: yup.boolean().default(true),
    formulaWelfare: yup.string().when('type', {
      is: (val) => val === 'recommendations' && isFormulaFieldSelected,
      then: yup.string().required('required'),
      otherwise: yup.string().notRequired(),
    }),
    offerCoverageRadius: yup.number().notRequired(),
    isOfferSelected: yup.boolean().notRequired(),
    offerCapacity: yup.number().notRequired(),
    offerCost: yup.number().notRequired(),
    currentOfferDatasetName: yup.string().notRequired(),
    currentOfferLayerName: yup.string().when('type', {
      is: (val) => val === 'recommendations' && isOfferSelected,
      then: yup.string().required('required'),
      otherwise: yup.string().notRequired(),
    }),
    capacityVariable: yup.string().notRequired(),
    isOfferCapacitySelected: yup.boolean().notRequired(),
    isFormulaFieldSelected: yup.boolean().notRequired(),

    recommendedPointLayerOpacity: yup.number().notRequired(),
    recommendedPointLayerName: yup.string().notRequired(),
    recommendedPointLayerColor: yup.string().notRequired(),
    recommendedPointLayerVisibility: yup.boolean().notRequired(),

    recommendedAreaLayerOpacity: yup.number().notRequired(),
    recommendedAreaLayerColor: yup.string().notRequired(),
    recommendedAreaLayerVisibility: yup.boolean().notRequired(),

    currentOfferAreaLayerOpacity: yup.number().notRequired(),
    currentOfferAreaLayerColor: yup.string().notRequired(),
    currentOfferAreaLayerVisibility: yup.boolean().notRequired(),

    circuchroneTable: yup.string().notRequired(),

    targetDemands: yup.string().notRequired(),
    isGlobal: yup.boolean().notRequired(),
    userOwner: yup.string().notRequired(),
    allowsRegenerateServicePoint: yup.boolean().notRequired(),
    potentialOffersDataset: yup.string().notRequired(),
    isPotentialOffersDatasetSelected: yup.boolean().notRequired(),

    // custom layers

    coverageRadiusLabel: yup.string().notRequired(),
    costLabel: yup.string().notRequired(),
    recommendationsOfferCapacityLabel: yup.string().notRequired(),
    recommendationsBudgetLabel: yup.string().notRequired(),
    recommendationsExpansionCapacityLabel: yup.string().notRequired(),
    recommendationsExpansionCostLabel: yup.string().notRequired(),
    recommendationsDemandDensityLabel: yup.string().notRequired(),
    variableWelfareLabel: yup.string().notRequired(),
    recommendationsFormulaPriorizationVariableLabel: yup.string().notRequired(),
    isTargetDemandsFieldSelected: yup.boolean().notRequired(),
    polygonDisplayCol: yup.string().notRequired(),
    isCostVisible: yup.boolean().notRequired(),
    isOfferCapacityVisible: yup.boolean().notRequired(),
    isExpansionCapacityVisible: yup.boolean().notRequired(),
    isExpansionCostVisible: yup.boolean().notRequired(),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(),
    mode: 'all',
  });


  const {
    handleSubmit,
    formState: {errors, isValid},
    watch,
    setValue,
    register,
    trigger,
    control,
    resetField,
    getValues
  } = methods;

  const {
    fields: fieldsCategory,
    append: appendCategory,
    remove: removeCategory,
  } = useFieldArray({
    name: 'customCategoryColors',
    control,
  });

  const type = watch('type');
  const calculator = watch('calculator');
  const order = watch('order')
  const alias = watch('alias');
  const nameWidget = watch('name');

  const [currentOfferDatasetName, setCurrentOfferDatasetName] = React.useState('');
  const densityVariable = watch('densityVariable');
  const welfareVariable = watch('welfareVariable');
  const capacityVariable = watch('capacityVariable');
  const isOfferCapacitySelected = watch('isOfferCapacitySelected');
  const offerCapacity = watch('offerCapacity');
  const isOfferSelected = watch('isOfferSelected');
  const [demandDatasetName, setDemandDatasetName] = React.useState(widget?.params?.demandDatasetName);
  const filter = type && type === 'histogram' ? 'NUMERICAL' : undefined;
  const offerCost = parseInt(watch('offerCost'));
  const isGlobal = watch('isGlobal');
  const allowsRegenerateServicePoint = watch('allowsRegenerateServicePoint');

  const selectedLayer = watch('layer');
  const datasetName = layers?.find((l) => l.id === selectedLayer)?.datasetName;
  const currentOfferLayerName = watch('currentOfferLayerName');
  const [potentialOffersDatasetLayerName, setPotentialOffersDatasetLayerName] = React.useState('');
  const isPotentialOffersDatasetSelected = watch('isPotentialOffersDatasetSelected');
  const potentialOffersDataset = watch('potentialOffersDataset');

  const targetDemands = watch('targetDemands');
  const description = watch('description');
  const recommendedPointLayerColor = watch('recommendedPointLayerColor');
  const recommendedPointLayerOpacity = watch('recommendedPointLayerOpacity');
  const recommendedAreaLayerColor = watch('recommendedAreaLayerColor');
  const recommendedAreaLayerOpacity = watch('recommendedAreaLayerOpacity');
  const recommendedAreaLayerVisibility = watch('recommendedAreaLayerVisibility');
  const currentOfferAreaLayerColor = watch('currentOfferAreaLayerColor');
  const currentOfferAreaLayerOpacity = watch('currentOfferAreaLayerOpacity');
  const currentOfferAreaLayerVisibility = watch('currentOfferAreaLayerVisibility');

  const coverageRadiusLabel = watch('coverageRadiusLabel');
  const costLabel = watch('costLabel');
  const recommendationsOfferCapacityLabel = watch('recommendationsOfferCapacityLabel');
  const recommendationsBudgetLabel = watch('recommendationsBudgetLabel');
  const recommendationsExpansionCapacityLabel = watch('recommendationsExpansionCapacityLabel');
  const recommendationsExpansionCostLabel = watch('recommendationsExpansionCostLabel');
  const recommendationsDemandDensityLabel = watch('recommendationsDemandDensityLabel');
  const variableWelfareLabel = watch('variableWelfareLabel');
  const recommendationsFormulaPriorizationVariableLabel = watch('recommendationsFormulaPriorizationVariableLabel');
  const layerColor = watch('layerColor');
  const layerOpacity = watch('layerOpacity');
  const layerBorderColor = watch('layerBorderColor');
  const layerBorderWidth = watch('layerBorderWidth');
  const datasetColumn = watch('datasetColumn');
  const customCategoryColors = watch('customCategoryColors');
  const polygonDisplayCol = watch('polygonDisplayCol');

  const isCostVisible = watch('isCostVisible');
  const isOfferCapacityVisible = watch('isOfferCapacityVisible');
  const isExpansionCapacityVisible = watch('isExpansionCapacityVisible');
  const isExpansionCostVisible = watch('isExpansionCostVisible');

  const {
    data: columns,
    isSuccess,
    refetch: refetchDatasetColumn
  } = useDataSetColumns(
    layers?.filter((l) => l.id === selectedLayer)[0]?.datasetName, filter
  );

  let columnsList = columns;
  if ((type === 'list' || type === 'statistics') && isSuccess && selectedLayer && columns) {
    columnsList = [...columns]
  }

  const {
    data: polyColumns,
    isSuccess: isSuccessPoly,
    refetch: refetchPolyColumns
  } = usePolygonDataSetColumns(
    selectDataset ?
      selectDataset[0]?.cartoName : '', filter, action == 'update'
  )

  const {
    data: demandDatasetColumns,
    isSuccess: demandDatasetColumnsIsSuccess,
    refetch: refetchColumns,
    isError: demandDatasetColumnsIsError
  } = useDataSetColumns(
    demandDatasetName,
    filter
  );

  const getColumns = (demandDatasetName, filter) => {
    refetchColumns(demandDatasetName, filter);
  }

  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);

  const haveNewListWidgetEmptyFields = () => {
    return (type === 'list' && columnsListOrder.length === 0);
  }

  const haveNewStatisticWidgetEmptyFields = () => {
    return (type === 'statistic' && columnsListOrderStats.length === 0)
  }

  React.useEffect(() => {
    if (type === 'histogram') {
      methods.setValue('datasetColumn', '');
    }
  }, [calculator]);

  React.useEffect(() => {
    if (type === 'statistic') {
      setIsWidgetFormBtnDisabled((
          !(isDirty && isValid)) ||
        haveNewStatisticWidgetEmptyFields()
      );
    } else if (type === 'list') {
      setIsWidgetFormBtnDisabled((
          !(isDirty && isValid)) ||
        haveNewListWidgetEmptyFields()
      );
    } else {
      setIsWidgetFormBtnDisabled(
        !(isDirty && isValid)
      );
    }

  }, [isDirty, isValid, columnsListOrder, columnsListOrderStats]);

  React.useEffect(() => {
    // Trigger validation for datasetColumn each time it changes
    trigger('datasetColumn');
  }, [datasetColumn, trigger]); // Add trigger to the dependencies array
  const getDatasetColumn = () => {
    if (type === 'category') {
      if (action === 'create'){
        if (datasetColumn !== 'CASE ELSE \'\' END'){
          return datasetColumn
        }
      }else {
        if (datasetColumn !== 'CASE ELSE \'\' END'){
          return datasetColumn
        }
      }
    }
  }
  const {
    data: categories,
    isSuccess: isSuccessCategories
  } = useDataSetColumnValues({
    dataSet: layers?.find((l) => l.id === selectedLayer)?.datasetName,
    column: getDatasetColumn(),
    histogramType: type === 'histogram',
    parametersCatalog:definedParametersCatalog,
    enabled: calculator ? (!!watch('datasetColumn') && isCaseGenerationValid) : !!watch('datasetColumn')
  });

  React.useEffect(() => {
    refetchDatasetColumn();
  }, [filter])

  React.useEffect(() => {
    if (type === 'recommendations' && formula[0]?.formula ) {
      methods.setValue('formulaWelfare', formula[0]?.formula);
    }
  }, [formula]);

  React.useEffect(() => {
    if (type === 'recommendations' && targetDemandsFormula[0].formula ) {
      methods.setValue('targetDemands', targetDemandsFormula[0]?.formula);
    }
  }, [targetDemandsFormula]);

  const findRepeatNames = (name, id) => {
    const foundWidgetName = _.find(widgets, {name});
    if (foundWidgetName?.id === id) return false;
    return foundWidgetName;
  };

  const formulaWelfare = watch('formulaWelfare');

  const recommendedPointLayerName = watch('recommendedPointLayerName');
  const [showRecommendedIcons, setShowRecommendedIcons] = React.useState(false);
  const [valueRecommendedIcons, setValueRecommendedIcons] = React.useState(action === 'update' ? widget?.params?.layersConfiguration?.recommendedPointLayerIcon : iconCustomDefault);

  // TODO: change to new data
  const constructorConditionalData = (data) => {
    const prepareHistogramColors = () => {
      const colors = palette_reverse ? [...gradient.colors].reverse() : gradient.colors;
      return colors.map((c, i) => ({
        bucket: i,
        color: c.color,
        stops: [
          {p: 0, c: c.color},
          {p: 25, c: c.color},
          {p: 50, c: c.color},
          {p: 75, c: c.color},
          {p: 100, c: c.color},
        ],
      }));
    };


    const listParams = action === 'create' ? columnsListOrder.map((c) => {
      let matchingObject = null;

      // Find the object with a matching alias
      if (!Array.isArray(formulaWithAlias)) {
        return
      }
      formulaWithAlias.forEach((object) => {

        if (object.alias === c.field) {
          matchingObject = object;
        }
      });

      const boolean = matchingObject ? true : false

      return {
        name: c.name,
        field: boolean ? matchingObject.formula : c.field,
        calculator: boolean ? true : false,
        alias: boolean ? matchingObject.alias : '',
        visible: c.visible,
        id: c.id,
        formula: boolean ? matchingObject.formula : c.field
      };
    }) : columnsListOrder.map((c) => {
      if (!Array.isArray(formulaWithAlias)) {
        return
      }
      let newItem = false
      formulaWithAlias.map((object) => {
        if (object.alias === c.field) {
          newItem = {
            name: c.name,
            field: object.formula,
            calculator: true,
            alias: object.alias,
            visible: c.visible,
            id: c.id,
            formula: object.formula
          };
        }
      })
      if (newItem) {
        return newItem
      } else if (c.calculator) {

        return {
          ...c,
          field: c.formula
        }
      } else {
        const response = {
          ...c,
          calculator: false,
          alias: ''
        }
        return response
      }
    })
    const getUniqueFieldName = (field, fieldCounts) => {
      fieldCounts[field] = (fieldCounts[field] || 0) + 1;
      return fieldCounts[field] > 1 ? `${field}${fieldCounts[field]}` : field;
    };
    const fieldCounts = {};
    const statsParams = action === 'create' ? columnsListOrderStats.map((c) => {
      if (!Array.isArray(formulaWithAlias)) {
        return
      }
      let matchingObject = null;

      // Find the object with a matching alias
      formulaWithAlias.forEach((object) => {

        if (object.alias === c.field) {
          matchingObject = object;
        }
      });

      const boolean = matchingObject ? true : false
      const uniqueName = getUniqueFieldName(decodeURIComponent(boolean ? matchingObject.formula : c.field), fieldCounts); // fieldCounts would be a state or a variable that tracks counts

      return {
        name: c.name,
        visible: c.visible,
        id: c.id,
        formatter: c.formatter,
        operation: c.operation,
        prefix: c.prefix,
        uniqueName: uniqueName,
        suffix: c.suffix,
        field: decodeURIComponent(boolean ? matchingObject.formula : c.field),
        calculator: boolean ? true : false,
        alias: boolean ? matchingObject.alias : uniqueName,
        formula: boolean ? matchingObject.formula : c.field

      };
    }) : columnsListOrderStats.map((c) => {
      if (!Array.isArray(formulaWithAlias)) {
        return
      }
      let newItem = false
      formulaWithAlias.map((object) => {
        if (object.alias === c.field) {

          newItem = {
            ...c,
            field: object.formula,
            calculator: true,
            alias: object.alias,
            formula: object.formula

          };

        }

      })
      if (newItem) {
        return newItem
      } else if (c.calculator) {

        return {
          ...c,
          field: c.formula
        }
      } else {
        const response = {
          ...c,
          calculator: false,
          alias: ''
        }
        return response
      }


    })


    const polygonParams = action === 'create' ? polyColumns?.reduce((accumulator, current) => {

      const matchingObject = columnsListOrderPoly.find(object => object.field === current.name);
      if (matchingObject) {
        accumulator.push({
          ...matchingObject,
          type: current.type
        });
      }
      return accumulator;
    }, []) : columnsListOrderPoly;

    const paramsBase = {
      field: data.datasetColumn || '',
      palette_id: data.gradientId,
      palette_reverse: data.palette_reverse,
      calculator: data.calculator,
      alias: data.alias
    };

    if (data.type === 'histogram')
      return {
        ...paramsBase,
        alias: formulaWithAlias[0]?.alias || widget?.params?.alias,
        histogramBuckets: 10,
        customHistogramColors: prepareHistogramColors(),
      };
    if (data.type === 'category')
      return {
        ...paramsBase,
        alias: formulaWithAlias.alias || widget?.params?.alias || '',
        colorType: data.colorType,
        categoryColor: data.categoryColor,
        customCategoryColors: data.colorType === 'by_value' ? data.customCategoryColors : [],
        order: data.order,
        colorInMap: data.colorInMap
      };
    if (data.type === 'list')
      return {
        palette_id: data.gradientId,
        palette_reverse: data.palette_reverse,
        list: listParams,
      };
    if (data.type === 'statistics')
      return {
        palette_id: data.gradientId,
        palette_reverse: data.palette_reverse,
        fields: statsParams,
      };
    if (data.type === 'polygon')
      return {
        ...paramsBase,
        list: polygonParams || [],
        layerColor: data.layerColor,
        layerBorderColor: data.layerBorderColor,
        layerOpacity: data.layerOpacity,
        layerBorderWidth: data.layerBorderWidth,
        dataset: selectDataset || widget?.params?.dataset,
        circuchroneTable: circuchroneTable || widget?.params?.circuchroneTable,
        polygonDisplayCol: data.polygonDisplayCol,
      };
    if(data.type==='parameters'){
      return {
        parametersCatalog:parameterCatalog||widget?.params?.parametersCatalog
      }
    }
    if (data.type === 'recommendations') {
      const recommendationsParams = {
        widgetId: randomId,
        demandDatasetName: demandDatasetName,
        densityVariable: data.densityVariable,
        offerCoverageRadius: offerCoverageRadius,
        offerCapacity: data.offerCapacity,
        offerCost: data.offerCost,
        currentOfferDatasetName: currentOfferDatasetName,
        currentOfferLayerName: data.currentOfferLayerName,
        capacityVariable: data.capacityVariable,
        isOfferCapacitySelected: isOfferCapacitySelected,
        isOfferSelected: isOfferSelected,
        layersConfiguration: {
          recommendedPointLayerOpacity: data.recommendedPointLayerOpacity || 1,
          recommendedPointLayerName: data.recommendedPointLayerName,
          recommendedPointLayerColor: data.recommendedPointLayerColor || '#b6cbdb',
          recommendedPointLayerIcon: valueRecommendedIcons || iconCustomDefault,
          recommendedPointLayerVisibility: true,

          recommendedAreaLayerOpacity: data.recommendedAreaLayerOpacity || 25,
          recommendedAreaLayerColor: data.recommendedAreaLayerColor || '#b6cbdb',
          recommendedAreaLayerVisibility: data.recommendedAreaLayerVisibility,

          currentOfferAreaLayerOpacity: data.currentOfferAreaLayerOpacity || 25,
          currentOfferAreaLayerColor: data.currentOfferAreaLayerColor || '#ff0313',
          currentOfferAreaLayerVisibility: data.currentOfferAreaLayerVisibility,

        },
        targetDemands: data.targetDemands,
        isGlobal: data.isGlobal,
        userOwner: user.id,
        allowsRegenerateServicePoint: data.allowsRegenerateServicePoint,
        potentialOffersDataset: potentialOffersDataset,
        isPotentialOffersDatasetSelected: isPotentialOffersDatasetSelected,
        customLabels: {
          coverageRadius: data.coverageRadiusLabel || '',
          cost: data.costLabel || '',
          recommendationsOfferCapacity: data.recommendationsOfferCapacityLabel || '',
          recommendationsBudget: data.recommendationsBudgetLabel || '',
          recommendationsExpansionCapacity: data.recommendationsExpansionCapacityLabel || '',
          recommendationsExpansionCost: data.recommendationsExpansionCostLabel || '',
          recommendationsDemandDensity: data.recommendationsDemandDensityLabel || '',
          variableWelfare: data.variableWelfareLabel || '',
          recommendationsFormulaPriorizationVariable: data.recommendationsFormulaPriorizationVariableLabel || '',
        },
        regeneratedPotentialOffers: wasRecommendationsFormUpdated,
        fieldsToShow: {
          isCostVisible: data.isCostVisible,
          isOfferCapacityVisible: data.isOfferCapacityVisible,
          isExpansionCapacityVisible: data.isExpansionCapacityVisible,
          isExpansionCostVisible: data.isExpansionCostVisible,
        }
      };

      if (isFormulaFieldSelected) {
        recommendationsParams.welfareVariable = "";
        recommendationsParams.formulaWelfare = formulaWelfare;

      } else {
        recommendationsParams.welfareVariable = welfareVariable;
        recommendationsParams.formulaWelfare = "";
      }
      recommendationsParams.isFormulaFieldSelected = isFormulaFieldSelected;
      recommendationsParams.isTargetDemandsFieldSelected = isTargetDemandsFieldSelected;
      return recommendationsParams;
    }
    return paramsBase;
  };

  const newWidget = (data) => {
    if (findRepeatNames(data.name)) {
      setMessageToast(t('error_adding_new_widget'));
      setOpenToast(true);
      setSeverity('error');
      return;
    }

    let widgetBase = {
      id: randomId,
      name: data.name,
      description: data.description,
      type: data.type,
      layer: data.layer,
      default_tab: groupId,
      isDynamic: false,
      colorType: data.colorType,
      default_display: true,
      visible: true,
      tags: [],
      params: constructorConditionalData(data),
    };

    if (type === 'polygon') {
      widgetBase = {
        ...widgetBase,
        dataSet: selectDataset[0],
        polygonLimit: polygonLimitValue,
        layerColor: data.layerColor,
        circuchroneTable: {
          cartoName: circuchroneTable[0]?.cartoName,
          prosperiaName: circuchroneTable[0]?.prosperiaName
        } || data.circuchroneTable
      }
    }
    dispatch(setWidget(widgetBase));
    if (type === 'recommendations') {
      createPotentialOffers();
      setWasRecommendationsFormUpdated(false);
    } else {
      onClose();
    }
  };

  const updWidget = (data) => {
    if (findRepeatNames(data.name, widget.id)) {
      setMessageToast(t('error_adding_new_widget'));
      setOpenToast(true);
      setSeverity('error');
      return;
    }

    const widgetBase = {
      id: widget.id,
      name: data.name,
      description: data.description,
      type: data.type,
      layer: data.layer,
      default_tab: groupId,
      isDynamic: false,
      colorType: data.colorType,
      default_display: true,
      visible: data.visible,
      tags: [],
      params: constructorConditionalData(data),
    };

    dispatch(updateWidget(widgetBase));
    if (type === 'recommendations' && wasRecommendationsFormUpdated) {
      createPotentialOffers();
      setWasRecommendationsFormUpdated(false);
    } else {
      onClose();
      resetVariablesContent();
    }
  };
  const onSubmit = handleSubmit((data) => {
    action === 'update' ? updWidget(data) : newWidget(data)
  });

  const colorType = watch('colorType');

  const closeToast = () => {
    setOpenToast(false);
    setMessageToast('');
  }

  React.useEffect(() => {
    if (type === 'polygon' && action === 'create') {
      methods.setValue('layerColor', '#bfbfbf');
      methods.setValue('layerBorderColor', '#bfbfbf')
      methods.setValue('layerOpacity', 0.2)
      methods.setValue('layerBorderWidth', 1)
    } else if (type === 'recommendations' && action === 'create') {
      methods.setValue('recommendedPointLayerName', t('recommendations_point_layer'));
      methods.setValue('isGlobal', true);
      methods.setValue('offerCost', 1);
      methods.setValue('recommendedAreaLayerOpacity', 25);
      methods.setValue('recommendedPointLayerOpacity', 25);
      methods.setValue('currentOfferAreaLayerOpacity', 25);
      methods.setValue('currentOfferAreaLayerColor', '#03d7ff');
      methods.setValue('recommendedPointLayerColor', '#d320d6');
      methods.setValue('recommendedAreaLayerColor', '#1bd041');
      methods.setValue('recommendedAreaLayerVisibility', true);
      methods.setValue('currentOfferAreaLayerVisibility', true);
      methods.setValue('recommendedPointLayerVisibility', true);
      methods.setValue('allowsRegenerateServicePoint', true);
    }
  }, [type]);

  React.useEffect(() => {
    if (type === 'recommendations') {
      setWidgetInputNameStyle({
        height: 35,
        paddingBottom: 23
      })
    }
    if (type !== 'recommendations' && error) {
      setWidgetInputNameStyle({
        height: 35,
        paddingBottom: 0
      })
    }
    if (type !== 'recommendations' && !error) {
      setWidgetInputNameStyle({
        height: 35,
        paddingBottom: 23
      })
    }
  }, [type, error]);

  const parseEmptyOrUndefinedValues = (value) => {
    if (!value) {
      return null;
    }
    return value;
  }

  React.useEffect(() => {
    const widgetDefaultValues = getDefaultValues();
    if (
      action === 'update' &&
      widgetDefaultValues.name !== nameWidget ||
      widgetDefaultValues.description !== description ||
      (
        parseEmptyOrUndefinedValues(widgetDefaultValues.datasetColumn) !== parseEmptyOrUndefinedValues(datasetColumn) &&
        parseEmptyOrUndefinedValues(String(widgetDefaultValues.datasetColumn)) !== parseEmptyOrUndefinedValues(datasetColumn) &&
        parseEmptyOrUndefinedValues(widgetDefaultValues.datasetColumn) !== parseEmptyOrUndefinedValues(String(datasetColumn))
      )
    ) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }

    if (action === 'create') {
      setIsDirty(true);
    }

    if ((action === 'update' && type === 'category') &&
      (widgetDefaultValues.order !== order ||
        widgetDefaultValues.colorType !== colorType ||
        widgetDefaultValues.colorInMap !== colorInMap ||
        gradient.id !== widget?.params.palette_id ||
        palette_reverse !== widget.params.palette_reverse)) {
      setIsDirty(true);
    }

    if(action==='update' && type==='parameters'){
      if(parameterCatalog.length!==widget?.params?.parametersCatalog?.length){
        setIsDirty(true);
      }else if(widget?.params?.parametersCatalog?.length>0){
        if(!_.isEqual(parameterCatalog,widget?.params?.parametersCatalog)){
          setIsDirty(true);
        }
      }

    }

    if ((action === 'update' && type === 'histogram') &&
      (gradient?.id !== widget?.params?.palette_id && !isEmptyObject(gradient) ||
        palette_reverse !== widget.params?.palette_reverse)) {
      setIsDirty(true);
    }

    if ((action === 'update' && type === 'recommendations') &&
      (widgetDefaultValues.formulaWelfare !== formulaWelfare ||
        widgetDefaultValues.welfareVariable !== welfareVariable ||
        widgetDefaultValues.isTargetDemandsFieldSelected !== isTargetDemandsFieldSelected ||
        ( widgetDefaultValues.targetDemands !== targetDemands && isTargetDemandsFieldSelected && !!targetDemands ) ||
        Number(widget?.params?.offerCoverageRadius) !== Number(offerCoverageRadius) ||
        Number(widgetDefaultValues.offerCost) !== Number(offerCost) ||
        Number(widgetDefaultValues.offerCapacity) !== Number(offerCapacity) ||
        widgetDefaultValues.layer !== selectedLayer ||
        widgetDefaultValues.densityVariable !== densityVariable ||
        ( parseEmptyOrUndefinedValues(widgetDefaultValues.currentOfferLayerName) !== parseEmptyOrUndefinedValues(currentOfferLayerName) || widgetDefaultValues.isOfferSelected !== isOfferSelected) ||
        widgetDefaultValues.potentialOffersDataset !== potentialOffersDataset ||
        widgetDefaultValues.capacityVariable !== capacityVariable ||
        widgetDefaultValues.recommendedPointLayerName !== recommendedPointLayerName ||
        widgetDefaultValues.recommendedPointLayerColor !== recommendedPointLayerColor ||
        widgetDefaultValues.recommendedPointLayerOpacity !== recommendedPointLayerOpacity ||
        widgetDefaultValues.recommendedPointLayerIcon !== valueRecommendedIcons ||
        widgetDefaultValues.recommendedAreaLayerColor !== recommendedAreaLayerColor ||
        widgetDefaultValues.recommendedAreaLayerOpacity !== recommendedAreaLayerOpacity ||
        widgetDefaultValues.recommendedAreaLayerVisibility !== recommendedAreaLayerVisibility ||
        widgetDefaultValues.currentOfferAreaLayerColor !== currentOfferAreaLayerColor ||
        widgetDefaultValues.currentOfferAreaLayerOpacity !== currentOfferAreaLayerOpacity ||
        widgetDefaultValues.currentOfferAreaLayerVisibility !== currentOfferAreaLayerVisibility ||
        widgetDefaultValues.allowsRegenerateServicePoint !== allowsRegenerateServicePoint ||
        widgetDefaultValues.coverageRadiusLabel !== coverageRadiusLabel ||
        widgetDefaultValues.costLabel !== costLabel ||
        widgetDefaultValues.recommendationsOfferCapacityLabel !== recommendationsOfferCapacityLabel ||
        widgetDefaultValues.recommendationsBudgetLabel !== recommendationsBudgetLabel ||
        widgetDefaultValues.recommendationsExpansionCapacityLabel !== recommendationsExpansionCapacityLabel ||
        widgetDefaultValues.recommendationsExpansionCostLabel !== recommendationsExpansionCostLabel ||
        widgetDefaultValues.recommendationsDemandDensityLabel !== recommendationsDemandDensityLabel ||
        widgetDefaultValues.variableWelfareLabel !== variableWelfareLabel ||
        widgetDefaultValues.recommendationsFormulaPriorizationVariableLabel !== recommendationsFormulaPriorizationVariableLabel) ||
        widgetDefaultValues.isCostVisible !== isCostVisible ||
        widgetDefaultValues.isOfferCapacityVisible !== isOfferCapacityVisible ||
        widgetDefaultValues.isExpansionCapacityVisible !== isExpansionCapacityVisible ||
        widgetDefaultValues.isExpansionCostVisible !== isExpansionCostVisible      
      ) {
      setIsDirty(true);
    }
    if ((action === 'update' && type === 'polygon') &&
      (
        widgetDefaultValues.layerColor !== layerColor ||
        widgetDefaultValues.layerOpacity !== layerOpacity ||
        widgetDefaultValues.layerBorderColor !== layerBorderColor ||
        widgetDefaultValues.layerBorderWidth !== layerBorderWidth ||
        columnsListOrderPoly.length !== widget.params.list.length || areFieldsInListDifferent({
          widgetColumns: widget.params.list,
          currentColumns: columnsListOrderPoly,
          type: widget.type
        }) ||
        widgetDefaultValues.polygonDisplayCol !== polygonDisplayCol
      )) {
      setIsDirty(true);
    }
    if ((action === 'update' && type === 'list') &&
      (columnsListOrder.length !== widget.params.list.length || areFieldsInListDifferent({
        widgetColumns: widget.params.list,
        currentColumns: columnsListOrder,
        type: widget.type
      }))) {
      setIsDirty(true);
    }
    if ((action === 'update' && type === 'statistics') &&
      (columnsListOrderStats.length !== widget.params.fields.length || areFieldsInListDifferent({
        widgetColumns: widget.params.fields,
        currentColumns: columnsListOrderStats,
        type: widget.type
      }))) {
      setIsDirty(true);
    }

  }, [
    nameWidget,
    description,
    datasetColumn,
    order,
    colorType,
    colorInMap,
    parameterCatalog,
    formulaWelfare,
    welfareVariable,
    targetDemands,
    offerCoverageRadius,
    offerCost,
    offerCapacity,
    selectedLayer,
    densityVariable,
    currentOfferLayerName,
    potentialOffersDataset,
    capacityVariable,
    recommendedPointLayerName,
    recommendedPointLayerColor,
    recommendedPointLayerOpacity,
    valueRecommendedIcons,
    recommendedAreaLayerColor,
    recommendedAreaLayerOpacity,
    recommendedAreaLayerVisibility,
    recommendedAreaLayerVisibility,
    currentOfferAreaLayerColor,
    currentOfferAreaLayerOpacity,
    currentOfferAreaLayerVisibility,
    allowsRegenerateServicePoint,
    coverageRadiusLabel,
    costLabel,
    recommendationsOfferCapacityLabel,
    recommendationsBudgetLabel,
    recommendationsExpansionCapacityLabel,
    recommendationsExpansionCostLabel,
    recommendationsDemandDensityLabel,
    variableWelfareLabel,
    recommendationsFormulaPriorizationVariableLabel,
    layerColor,
    layerOpacity,
    layerBorderColor,
    layerBorderWidth,
    columnsListOrder,
    columnsListOrderPoly,
    columnsListOrderStats,
    gradient,
    palette_reverse,
    isOfferSelected,
    polygonDisplayCol,
    isTargetDemandsFieldSelected,
    isCostVisible,
    isOfferCapacityVisible,
    isExpansionCapacityVisible,
    isExpansionCostVisible,
  ]);

  React.useEffect(() => {
    const associatedDatasetFromLayer = getAssociatedDatasetFromLayer(currentOfferLayerName);
    if (!associatedDatasetFromLayer) {
      const importedSelectedLayer = layers.filter(l => l.datasetName === getValues('currentOfferDatasetName'))[0]?.id;
      setValue('currentOfferLayerName', importedSelectedLayer)
    }
  }, []);

  React.useEffect(() => {
    if (type === 'recommendations' && widgetProcessJobStatus === processJobStatus.SUCCESS) {
      setWidgetProcessJobStatus(processJobStatus.WAITING);
      onClose();
    }
  }, [type, widgetProcessJobStatus]);

  React.useEffect(() => {
    if (type === 'recommendations' && action === 'update' && !areRecommendationFetchedWidgetValuesEqualsToWidgetFieldsForm()) {
      setWasRecommendationsFormUpdated(true);
    }
  }, [
    type,
    action,
    demandDatasetName,
    densityVariable,
    welfareVariable,
    formulaWelfare,
    targetDemands,
    offerCoverageRadius,
    offerCost,
    currentOfferLayerName,
    potentialOffersDataset,
    capacityVariable,
    offerCapacity,
    widget,
    isTargetDemandsFieldSelected,
  ]);

  React.useEffect(() => {
    if (currentOfferLayerName && currentOfferLayerName !== 'none') {
      setCurrentOfferDatasetName(getAssociatedDatasetFromLayer(currentOfferLayerName));
    } else if ( currentOfferLayerName === 'none' ) {
      resetPotentialOffersPredefinedLocations();
      setCurrentOfferDatasetName('')
    }
  }, [currentOfferLayerName]);


  React.useEffect(() => {
    setPotentialOffersDatasetLayerName(getAssociatedDatasetFromLayer(potentialOffersDataset));
  }, [potentialOffersDataset]);

  function assignColors(colors, n) {
    if (n === 1) return [colors[5].color]
    if (n > 10) {
      // Return an array of the 'color' property from each item in the 'colors' array
      return colors.map(colorItem => colorItem.color);
    }
    const assignedColors = [];
    const colorStep = (colors.length - 1) / (n - 1);
    for (let i = 0; i < n; i++) {
      const colorIndex = Math.round(i * colorStep);
      assignedColors.push(colors[colorIndex].color);
    }

    return assignedColors;
  }

  function extractCaseNames(datasetColumn) {
    const caseNameRegex = /THEN\s+'(.*?)'/g;
    let match;
    const caseNames = [];

    while ((match = caseNameRegex.exec(datasetColumn)) !== null) {
      caseNames.push(match[1]); // Add the captured case name to the array
    }
    if (typeof datasetColumn === 'boolean') return caseNames
    // Handle the ELSE case, which is always last and does not follow the THEN pattern
    const elseCaseMatch = datasetColumn.match(/ELSE\s+'(.*?)'/);
    if (elseCaseMatch) {
      caseNames.push(elseCaseMatch[1]);
    }

    return caseNames;
  }

  React.useEffect(() => {
    if (type === 'category' && datasetColumn === undefined) return
    if (type === 'category' && (categories || extractCaseNames(datasetColumn).length !== 0) && activeColors.length > 0) {
      const realCategories = calculator && type === 'category' ? extractCaseNames(datasetColumn) : categories
      const assignedColors = assignColors(activeColors, realCategories.length)
      const typeVar = typeof realCategories[0];
      removeCategory();

      if (typeVar === 'string' || (typeVar === 'number' && realCategories?.length <= 10)) {
        realCategories?.forEach((c, i) => {
          if (i <= activeColors.length - 1) {
            appendCategory({color: assignedColors[i], value: c});
          } else {
            appendCategory({
              color: activeColors[i % activeColors.length].color,
              value: c
            });
          }
        });
      } else if (typeVar === 'boolean') {
        realCategories?.forEach((c, i) => {
          if (i <= activeColors.length - 1) {
            appendCategory({color: assignedColors[i], value: c.toString()});
          } else {
            appendCategory({
              color: activeColors[i % activeColors.length].color,
              value: c.toString()
            });
          }
        });
      }
      else if (typeVar === 'number') {
        let start, end;
        realCategories?.forEach((c, i) => {
          const valor = (realCategories[realCategories.length - 1] - realCategories[0]) / 10;
          if (i === 0) {
            start = realCategories[i];
            end = start + valor
            appendCategory({
              color: activeColors[i].color,
              value: start.toFixed(3) + ' - ' + end.toFixed(3)
            });
          } else if (i <= activeColors.length - 1) {
            start = end;
            end = start + valor;
            appendCategory({
              color: activeColors[i].color,
              value: start.toFixed(3) + ' - ' + end.toFixed(3)
            });
          }
        });
      }
    } else if (type === 'category' && categories && activeColors.length === 0 && palette_reverse) {
      const realCategories = calculator && type === 'category' ? extractCaseNames(datasetColumn) : categories


      let colorsReverse = gradient.colors === undefined ? assignColors(widget.params.customCategoryColors.reverse(), realCategories.length) : assignColors(gradient.colors?.reverse(), realCategories.length)
      if (action === 'update' && gradient.id != undefined && gradient.id != widget?.params.palette_id) {
        colorsReverse = gradient.colors.map(el => el.color);
      }
      const typeVar = typeof realCategories[0];
      removeCategory();

      if (typeVar === 'string' || (typeVar === 'number' && realCategories?.length <= 10)) {
        realCategories?.forEach((c, i) => {
          if (i <= colorsReverse.length - 1) {
            appendCategory({color: colorsReverse[i], value: c});
          } else {
            appendCategory({
              color: colorsReverse[i % colorsReverse.length],
              value: c
            });
          }
        });
      } else if (typeVar === 'boolean') {
        realCategories?.forEach((c, i) => {
          if (i <= activeColors.length - 1) {
            appendCategory({color: assignedColors[i], value: c});
          } else {
            appendCategory({
              color: activeColors[i % activeColors.length].color,
              value: c
            });
          }
        });
      } else if (typeVar === 'number') {
        let start, end;

        realCategories?.forEach((c, i) => {
          const valor = (realCategories[realCategories.length - 1] - realCategories[0]) / 10;
          if (i == 0) {
            start = realCategories[i];
            end = start + valor
            appendCategory({
              color: colorsReverse[i],
              value: start.toFixed(3) + ' - ' + end.toFixed(3)
            });
          } else if (i <= colorsReverse.length - 1) {
            start = end;
            end = start + valor;
            appendCategory({
              color: colorsReverse[i],
              value: start.toFixed(3) + ' - ' + end.toFixed(3)
            });
          }
        });
      }
    }
  }, [activeColors, categories, isSuccessCategories, datasetColumn]);

  React.useEffect(() => {
    if (gradientIsSuccess) {
      const idGradient = widget?.params?.palette_id;
      if (idGradient && gradients?.find((g) => g.id === idGradient)) {
        setGradient(gradients?.find((g) => g.id === idGradient));
      }else{
        setGradient(gradients?.find((g) => g.name === 'Gradient1'));
      }
    }
  }, [gradientIsSuccess]);

  React.useEffect(() => {
    if (type === 'category' && colorType === 'by_value' && !palette_reverse) {
      const putRandomColors = () => {
        let colors = gradient.colors;
        gradient.id === undefined ? colors = methods.getValues('customCategoryColors') : colors
        removeCategory();
        setActiveColors(colors);
      };
      if (isSuccessCategories && decodeURIComponent(widget?.params.field) !== decodeURIComponent(datasetColumn)) {
        putRandomColors();
      } else if (decodeURIComponent(widget?.params.field) === decodeURIComponent(datasetColumn)) {
        if (widget?.params.customCategoryColors?.length > 0) {
          setValue('customCategoryColors', widget.params.customCategoryColors);
          putRandomColors();
        } else putRandomColors();
      }
    } else if (type === 'category' && colorType === 'by_value' && palette_reverse) {
      const putRandomColors = () => {
        let colors = gradient.colors || [];
        if (action !== 'update') colors = [...colors].reverse();
        removeCategory();
        setActiveColors(colors);
      };
      if (isSuccessCategories && decodeURIComponent(widget?.params.field) !== decodeURIComponent(datasetColumn)) {
        putRandomColors();
      } else if (decodeURIComponent(widget?.params.field) === decodeURIComponent(datasetColumn)) {
        if (widget?.params.customCategoryColors?.length > 0) {
          setValue('customCategoryColors', widget.params.customCategoryColors);
          putRandomColors();
        } else putRandomColors();
      }
    }
  }, [colorType, gradient, palette_reverse]);

  React.useEffect(() => {
    if (widget && widget.type !== 'recommendations' && isSuccess) {
      setValue('datasetColumn', decodeURIComponent(widget.params.field));
    }
  }, [widget, isSuccess, setValue, watch]);

  React.useEffect(() => {
    if (isSuccess && type === 'list' && action === 'update') {
      const updatedColumns = widget.params.list.map((item) => {
        if (columns.indexOf(item.field) !== -1) {
          return {
            ...item,
            alias: '',
            calculator: false
          }
        }
        return {
          ...item,
          alias: item.alias,
          field: item.alias,
          formula: item.field,
          calculator: true

        }
      })
      setColumnListOrder(updatedColumns)
    }
    ;
  }, [isSuccess, type, widget]);

  React.useEffect(() => {
    if (isSuccess && type === 'statistics' && action === 'update') {
      const updatedColumns = widget.params.fields.map((item) => {
        if (columns.indexOf(item.field) !== -1) {
          return {
            ...item,
            alias: '',
            calculator: false
          }
        }
        return {
          ...item,
          alias: item.alias,
          field: item.alias,
          formula: item.field,
          calculator: true

        }
      })
      setColumnListOrderStats(updatedColumns)
    }
  }, [isSuccess, type, widget]);

  React.useEffect(() => {
    if (isSuccess && type === 'polygon' && action === 'update') {
      setColumnsListOrderPoly(widget.params.list);
    }
  }, [isSuccess, type, widget]);

  React.useEffect(() => {
    if (type === 'histogram' && methods.getValues('datasetColumn') == undefined) gradient?.colors?.reverse();

    type === 'histogram' && action === 'create' && !palette_reverse ? gradient.colors?.reverse() : gradient?.colors;
    type === 'category' && action === 'create' && !palette_reverse && methods.getValues('datasetColumn') != undefined ? gradient.colors?.reverse() : gradient?.colors;

    if (type === 'category' && action === 'update' && !palette_reverse && gradient.id === undefined)
      gradient.colors?.reverse();
    if (type === 'category' && action === 'update' && !palette_reverse && gradient.id != undefined && gradient.id != widget?.params.palette_id)
      gradient?.colors.reverse();
    if (type === 'category' && action === 'update' && palette_reverse && gradient.colors !== undefined)
      gradient?.colors?.reverse();
    setValue('gradientId', gradient?.id);
  }, [gradient]);

  React.useEffect(() => {
    setValue('palette_reverse', palette_reverse);
  }, [palette_reverse]);
  React.useEffect(() => {
    setValue('colorInMap', colorInMap)
  }, [colorInMap])

  React.useEffect(() => {
    if (type === 'recommendations') {
      if (steps[steps.length - 1].label !== t('glossary')) {
        const recommendationsSteps = [
          {
            label: t('demand_setup'),
            description: t('widget_demand_data_info'),
          },
          {
            label: t('offer_setup'),
            description: t('service_point_data_info')
          },
          {
            label: t('recommendations_setup'),
            description: t('recommendations_info'),
          },
          {
            label: t('glossary'),
            description: t('glossary_terms_info')
          },
        ];

        const updatedSteps = steps.filter(step =>
          step.label !== t('widget_specific_data_label')
        );
        setSteps([...updatedSteps, ...recommendationsSteps]);
      }
    } else if (type === 'polygon') {
      const polygonSteps = [
        {
          label: 'Layer Configuration'
        }
      ]
      setSteps([...initialSteps, ...polygonSteps]);
    } else {
      setSteps(initialSteps);
    }
  }, [type])

  React.useEffect(() => {
    switch (activeStep) {
      case 0 :
        setDisableButtonStep((!type || type === ""))
        break;
      case 1 :
        setDisableButtonStep(false)
        break;
    }
  }, [type, selectedLayer, nameWidget, activeStep]);

  React.useEffect(() => {
    if (demandDatasetName && type === 'recommendations') {
      getColumns(demandDatasetName, filter);
      methods.setValue('demandDatasetName', demandDatasetName);
    }
  }, [demandDatasetName]);

  const areFieldsInListDifferent = (listsToCompare) => {
    if (listsToCompare.widgetColumns.length !== listsToCompare.currentColumns.length) {
      return true;
    }
    for (let i = 0; i < listsToCompare.widgetColumns.length; i++) {
      const currentListItemFounded = listsToCompare.currentColumns.find(currentColumnField => currentColumnField.name === listsToCompare.widgetColumns[i].name);
      if (!currentListItemFounded) {
        return true;
      }
      for (let key in currentListItemFounded) {
        if (currentListItemFounded.hasOwnProperty(key) && listsToCompare.widgetColumns[i].hasOwnProperty(key)) {
          if (listsToCompare.type !== 'list') {
            // el campo "alias" se definio para el widget de estadistica, por alguna razon no tienen valores iguales
            if (key !== 'alias' && currentListItemFounded[key] !== listsToCompare.widgetColumns[i][key]) {
              return true;
            }
          } else {
            // este codigo se annadio pq la estructura en el widget de lista cambia cdo tiene formula, no coinciden los valores en lo campos field y alias
            if (key === 'field' && currentListItemFounded.calculator && (currentListItemFounded['field'] !== listsToCompare.widgetColumns[i]['alias'])) {
              return true;
            }
            if (key !== 'field' && currentListItemFounded[key] !== listsToCompare.widgetColumns[i][key]) {
              return true;
            }
          }
        } else {
          if (parseEmptyOrUndefinedValues(currentListItemFounded[key]) === parseEmptyOrUndefinedValues(listsToCompare.widgetColumns[i][key])) {
            return false
          }
          // deshabilita la deteccion de campos diferentes en los atributos no existentes en algunos de los objetos bajo analisis
          // return true;
        }
      }
    }
    return false;
  }

  const goToStep = (index) => {
    switch (index) {
      case 1 :
        type && type !== "" ? setActiveStep(index) : null;
        break;
      case 2 :
        (selectedLayer && nameWidget !== '') || type === 'polygon' ? setActiveStep(index) : null;
        break;
      default:
        setActiveStep(index);
    }
  }
  const {user} = useAuth();
  const dataset = useDataSetsOrg(giveMeId(location.pathname, user));

  const onChangeDataSetName = (value) =>{
    if(value !== undefined && value !== ''){
      setSelectDataset(dataset.data?.filter((d) => d.cartoName === value))
    }
  }


  const onChangeDataSetAuxName = (value) => {
    if (value) {
      const result = dataset.data?.filter((d) => d.cartoName === value)
      setCircuchroneTable(result)
      methods.setValue('circuchroneTable', result[0].prosperiaName)
    }
  }

  const onChangeLayerName = (value) => {
    if (value) {
      setSelectLayer(value);
      const associatedDatasetFromLayer = getAssociatedDatasetFromLayer(value);
      setDemandDatasetName(associatedDatasetFromLayer);
    }
  }

  const performActionsAfterUpload = () => {
    setPrivateLoading(true);
    setMessageToast(t('generating_potential_offers'));
    setSeverity('info');
    setShowProgressBar(true);
  };

  const showMessage = (message, severity = messageSeverity.SUCCESS) => {
    setSeverity(severity);
    setMessageToast(message);
  };

  const handleProcessJobResultCheck = (message, severity, intervalHandler) => {
    showMessage(message, severity);
    setPrivateLoading(false);
    clearInterval(intervalHandler);
  };

  const getLanguage = () => {
    switch (lng) {
      case 'EN':
        return 'english';
      case 'FR':
        return 'french';
      case 'PO':
        return 'portuguese';
      case 'ES':
        return 'spanish';
      default:
        return 'english';
    }
  }

  const createPotentialOffers = async () => {
    const offerCapacityContent = isOfferCapacitySelected ? parseInt(offerCapacity) : 0
    let user_name = '';
    const organizationId = location.pathname.split('/')[2];
    if (isGlobal) {
      user_name = mapId + '_' + organizationId;
    } else {
      user_name = mapId + '_' + user.id;
    }
    const payload = {
      payload: {
        "demand_dataset_name": demandDatasetName,
        "quant_var": densityVariable,
        "w_var": welfareVariable,
        "coverage": parseInt(offerCoverageRadius),
        "capacity_lower_bound": offerCapacityContent,
        "capacity_upper_bound": offerCapacityContent + 1,
        "cost_lower_bound": offerCost,
        "cost_upper_bound": offerCost + 1,
        "user_name": user_name,
        "scenario_id": randomId,
        "notification_host": process.env.REACT_APP_PROCESS_JOB_ENDPOINT + apiPaths.processJob,
        "lang": getLanguage()
      }
    }

    if ( isTargetDemandsFieldSelected ) {
      payload.payload.demand_custom_filter = getValues("targetDemands");
    }

    if ( currentOfferDatasetName && isOfferSelected && currentOfferLayerName !== 'none' ) {
      payload.payload.current_offer_dataset_name = currentOfferDatasetName;
      if ( capacityVariable && capacityVariable !== 'none' ) {
        payload.payload.current_offer_capacity_var = capacityVariable;
      }
    }

    if (isPotentialOffersDatasetSelected) {
      payload.payload.pot_o_locations_dataset_name = potentialOffersDatasetLayerName;
    }

    generatePotentialOffers(payload).then((response) => {
      performActionsAfterUpload();
      if (response.value) {
        const processJobId = response.value.processJobId;
        let intervalMaxFailedRequest = 10;
        let intervalFailedRequestCount = 0;
        let intervalHandler = setInterval(() => {
          if (intervalFailedRequestCount === intervalMaxFailedRequest) {
            setShowProgressBar(false);
            setPrivateLoading(false);
            clearInterval(intervalHandler);
            showMessage(t('incluia_error_generating_potential_offers'), 'error');
          }

          axios.get(`${apiPaths.processJob}/${processJobId}`, status200).then(processJobResponse => {
            if (processJobResponse && processJobResponse.status === 200 && processJobResponse.data) {
              if (processJobResponse.data.value) {
                const processJob = processJobResponse.data.value;
                if (processJob.status === processJobStatus.INPROGRESS) {
                  setProgress(processJob.progress);
                  setNotificationMessage(processJob.message);
                  setWidgetProcessJobStatus(processJobStatus.INPROGRESS);
                }
                if (processJob.status === processJobStatus.SUCCESS) {
                  if (action === 'update') {
                    const recommendationsDatasetName = `incluia-project.incluia_datasets.user_${user_name}_scenario_${randomId}_recommended_offers`;
                    dropDatasetTableByName(recommendationsDatasetName);
                  }
                  setShowProgressBar(false);
                  handleProcessJobResultCheck(
                    t('potential_offers_successfully_generated'),
                    messageSeverity.SUCCESS, intervalHandler
                  );
                  setWidgetProcessJobStatus(processJobStatus.SUCCESS);
                }
                if (processJob.status === processJobStatus.FAILED) {
                  setShowProgressBar(false);
                  setPrivateLoading(false);
                  clearInterval(intervalHandler);
                  showMessage(`${t('incluia_error_generating_potential_offers')}: ${processJob.message}`, 'error');
                  setWidgetProcessJobStatus(processJobStatus.FAILED);
                }
              } else {
                setShowProgressBar(false);
                setPrivateLoading(false);
                clearInterval(intervalHandler);
                showMessage(`${t('incluia_error_generating_potential_offers')}: ${processJob.message}`, 'error');
                setWidgetProcessJobStatus(processJobStatus.FAILED);
              }
            } else {
              intervalFailedRequestCount++;
            }
          }).catch(err => {
            setShowProgressBar(false);
            setPrivateLoading(false);
            clearInterval(intervalHandler);
            setWidgetProcessJobStatus(processJobStatus.FAILED);
            showMessage(t('incluia_error_generating_potential_offers'), 'error');
          });

        }, 1600);
      }
    });
  };

  const offerCoverageRadiusHandleChange = (event, value) => {
    setOfferCoverageRadius(Number(value) || Number(event.target.value));
  }

  const resetVariablesContent = () => {
    recommendationsGeneratedSuccessfully.current = false
  }

  const getAssociatedDatasetFromLayer = (selectedLayer) => {
    const datasetAssociated = layers.find((layerItem => {
      return layerItem.id === selectedLayer
    }))?.datasetName;
    return datasetAssociated;
  }


  const handleInputIcon = (value) => {
    if (value !== '') {
      if (showRecommendedIcons) {
        setValueRecommendedIcons(value + '.svg');
        setShowRecommendedIcons(!showRecommendedIcons);
      }
    }
  }

  const resetPotentialOffersPredefinedLocations = () => {
    resetField('potentialOffersDataset', {defaultValue: ''});
    setPotentialOffersDatasetLayerName('');
  }

  React.useEffect(() => {
    // This effect runs when the component mounts and whenever 'type' or 'action' changes
    if (type === 'list' && action === 'update') {
      // Filter items that have a non-empty alias property
      const itemsWithAlias = widget?.params?.list.filter(item => item.alias !== '');
      setFormulaWithAlias(itemsWithAlias);
    }
  }, [type, action]);

  React.useEffect(() => {
    // This effect runs when the component mounts and whenever 'type' or 'action' changes
    if (type === 'statistics' && action === 'update') {
      // Filter items that have a non-empty alias property
      const itemsWithAlias = widget?.params?.fields.filter(item => item.field !== item.alias);
      setFormulaWithAlias(itemsWithAlias);
    }
  }, [type, action]);

  React.useEffect(() => {
    setValue('order', orderByValue)
  }, [orderByValue])

  React.useEffect(() => {
    if(type === 'category' && action === 'update') setOrderByValue(widget.params.order)
  }, [action])

  React.useEffect(() => {
    //*Agregar un cuarto paso para categoria e histograma
    if ((type === 'category' || type === 'histogram') && calculator && steps.length <= 3) {
      setSteps(prevSteps => [
        ...prevSteps,
        {
          label: t('visuality'),
        }
      ]);
    } else if ((type === 'category') && !calculator && steps.length > 3) {
      setSteps(prevState => prevState.slice(0, -1));
    }
  }, [calculator]);

  React.useEffect(() => {
    setListColumns(orderColumn);
  }, [orderColumn]);

  React.useEffect(() => {
    if (isSuccess && polyColumns){
      let colNotSelected = polyColumns?.map(str => ({ field: str.name, name: str.name , show : false, id: Date.now().toString(36) + Math.random().toString(36)}));
      colNotSelected = colNotSelected?.filter(obj1 =>
        !polyColumns.some(obj2 => obj2.field === obj1.field)
      );
      setListColumns(colNotSelected)
    }
  }, [polyColumns]);

  const isRecommendationsVisualization = React.useMemo(() => {
    return activeStep === 4 && type === 'recommendations'
  }, [activeStep, type]);

  const areRequiredFieldsEmpty = React.useMemo(() => {
    if ( activeStep === 0 && !type) {
      return true;
    }
    if ( activeStep === 1 ) {
      return !nameWidget;
    } else if ( activeStep === 2 && type === 'recommendations' ) {
      return !selectedLayer || !densityVariable || ( !welfareVariable && !formulaWelfare );
    }
    return false;
  }, [
    activeStep,
    type,
    nameWidget,
    selectedLayer,
    densityVariable,
    formulaWelfare,
    welfareVariable,
  ]);

  const isFormValid = React.useMemo( async () => {
    return await schema.isValid();
  }, [activeStep]);

  function isEmptyObject(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  const areRecommendationFetchedWidgetValuesEqualsToWidgetFieldsForm = () => {
    return (
      demandDatasetName === widget?.params?.demandDatasetName &&
      densityVariable === widget?.params?.densityVariable &&
      (welfareVariable === widget?.params?.welfareVariable && formulaWelfare.replace(/\s+/g, '') === widget?.params?.formulaWelfare.replace(/\s+/g, '')) &&
      targetDemands === (widget?.params?.targetDemands || '') &&
      Number(offerCoverageRadius) === Number(widget?.params?.offerCoverageRadius) &&
      Number(offerCost) === Number(widget?.params?.offerCost) &&
      parseEmptyOrUndefinedValues(currentOfferLayerName) === parseEmptyOrUndefinedValues(widget?.params?.currentOfferLayerName) &&
      potentialOffersDataset === (widget?.params?.potentialOffersDataset || '') &&
      capacityVariable === (widget?.params?.capacityVariable || '') &&
      Number(offerCapacity) === Number(widget?.params?.offerCapacity || 1) &&
      isTargetDemandsFieldSelected === widget?.params?.isTargetDemandsFieldSelected
    )
  }

  const isLastStep = () => activeStep === steps.length - 1

  const disabledContinueButton = () =>
    type === 'category'  && activeStep === 2 && disabledContinue;

  const disabledInRecommendations = () =>
    (type === 'recommendations'  && activeStep === 2 && !isFormulaValidate && calculator) ||
  (type === 'recommendations'  && activeStep === 2 && !isTargetDemandFormulaValidate && isTargetDemandsFieldSelected);

  const handleNext = () => {
    if((activeStep !== 2 || type !== 'category') || disabledContinueButton()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }else {
      setGoCheckCaseStructure(true)
    }
    if(type === 'category' && action === 'update') {
      setValue('customCategoryColors', widget.params?.customCategoryColors)
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChangeOrderBy = (event, newValue) => setOrderByValue(newValue);

  const handleChangePolygonLimit = (event, newValue) => setPolygonLimitValue(newValue);

  const handleChangeInputLimit = (event) => {
    const newValue = event.target.value === '' ? '' : Number(event.target.value);
    if (newValue >= 1 && newValue <= 500) {
      setPolygonLimitValue(newValue);
    }
  };

  const handleSetItem = (value) => setOrderColumn(value);

  const handleLayerOpacityChange = (event) => {
    const value = parseFloat(event.target.value);
    if (value >= 0.2 && value <= 1) setValue('layerOpacity', value);
  };

  const handleBorderWidthChange = (event) => {
    const value = parseFloat(event.target.value);
    if (value >= 0.2 && value <= 10) setValue('layerBorderWidth', value);
  };

  React.useEffect(() => {
    const isCategoryUpdate = type === 'category' && action === 'update';
    const isActiveStep = activeStep === 3;

    if (isCategoryUpdate && widget.params?.customCategoryColors && isActiveStep) {
      setIsWidgetFormBtnDisabled(false)
    }
  }, [JSON.stringify(customCategoryColors)]);

  function hasAttributes(obj) {
    return Object.keys(obj).length > 0;
  }

  const isFormInvalid = React.useMemo( () => {
    const isYupSchemaValid = hasAttributes( errors );
    return isYupSchemaValid;
  }, [
    activeStep,
    hasAttributes( errors ),
  ]);

  const tdform = {
    methods,
    makeid,
    setFormulaWithAlias,
    setColumnListOrderFormula,
    columns: columns || [],
    definedParametersCatalog,
    action,
    errors,
    datasetColumn,
    widget,
    formulaWithAlias,
    type,
    alias,
    columnsListOrder,
    isTargetDemandsFieldSelected,
    setValidFormula,
    formulaErrors,
    setFormulaErrors,
    targetDemandsFormula,
    setTargetDemandsFormula,
    datasetName,
    setOpenToast,
    setIsFormulaValidate: setIsTargetDemandFormulaValidate
  }

  const wform = {
    methods,
    makeid,
    setFormulaWithAlias,
    setColumnListOrderFormula,
    columns: columns || [],
    definedParametersCatalog,
    action,
    isFormulaFieldSelected,
    errors,
    datasetColumn,
    widget,
    formulaWithAlias,
    type,
    alias,
    columnsListOrder,
    setValidFormula,
    formulaErrors,
    setFormulaErrors,
    formula,
    setFormula,
    datasetName,
    setOpenToast,
    setIsFormulaValidate,
  }

  const actions = (
    <div style={{marginTop:'2rem'}}>
      {!isLastStep() ? (
        <>
          <MuiBtnClose
            disabled={activeStep === 0}
            variant='text'
            onClick={handleBack}
            style={{ mt: 1, mr: 1, display: activeStep === 0 ? 'none' : 'inline-flex' }}
          >
            <MuiTypography variant='body1' style={{marginTop:8}}>
              {t('widget_button_back_label')}
            </MuiTypography>
          </MuiBtnClose>
          <MuiBtnAction
            variant='outlined'
            onClick={handleNext}
            sx={{mt: 1, mr: 1}}
            style={{
              marginRight: 10,
              width:validatingStructureCase ? '175px' : '126px'
            }}
            disabled={disableButtonStep || disabledContinueButton() || disabledInRecommendations() || areRequiredFieldsEmpty || isFormInvalid }
            data-cy={'button_continue'}
            startIcon={validatingStructureCase ? <MuiRefreshIcon sx={validateCaseStyle}/> : ''}
          >
            <MuiTypography variant='body1'>
              {validatingStructureCase ? t('validating') : t('widget_button_continue_label') }
            </MuiTypography>
          </MuiBtnAction>
        </>
      ):(
        <>
          <MuiBtnClose
            onClick={onClose}
            color='primary'
            variant='text'>
            <MuiTypography variant='body1' >
              {t('cancel_btn')}
            </MuiTypography>
          </MuiBtnClose>
          <MuiBtnAction
            onClick={onSubmit}
            color='primary'
            variant='outlined'
            loading={false}
            disabled={isWidgetFormBtnDisabled}
            data-cy={'save_widget'}>
            <MuiTypography>
              {t('save_changes').toUpperCase()}
            </MuiTypography>
          </MuiBtnAction>
        </>
      )}
    </div>
  );

  const widgetForm = (
    <Grid container>
      <Toast
        message={messageToast}
        handleClose={closeToast}
        severity={severity}
        horizontal='center'
        vertical='top'
        open={openToast}
      />
      <Loader
        loading={privateLoading}
        progress={progress}
        headerMessage={t('generating_potential_offers')}
        titleMessage={t('generating_potential_offers')}
        notificationMessage={notificationMessage}
      />
      <Grid container justifyContent='center'
            style={{alignContent: 'flex-start'}}>
        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} sx={stepperStyle}  orientation="horizontal">
              {steps.map((step, index) => (
                <Step key={step.label} >
                  <StepLabel StepIconComponent={MuiCustomStepIcon}>
                    <a onClick={() => goToStep(index)}
                       style={{
                         ...stepLabelStyle,
                         color:index === activeStep ? '#212121' : '#9E9E9E'
                       }}> {step.label}</a>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              <Grid container justifyContent='flex-start'>
                <MuiGridElementContainer item xs={12} style={{ marginBottom: isRecommendationsVisualization ? 26 : 0, marginTop:27 }}>
                  <MuiStepDescription>{steps[activeStep].description}</MuiStepDescription>
                </MuiGridElementContainer>
                {activeStep === 0 && (
                  <Grid item xs={12}>
                    <SelectWidgetType
                      name='type'
                      allDisabled={action === 'update'}/>
                  </Grid>)
                }
                {activeStep === 1 && (
                  <MuiGridElement item xs={12}>
                    <Grid container justifyContent='flex-start' style={{ alignContent: 'flex-start'}}>
                      {type !== 'recommendations' && type !== 'polygon' && type !== 'parameters' && (
                        <MuiGridElementContainer item xs={6}>
                          <SelectField
                            style= {{paddingTop: 0}}
                            name='layer'
                            error={t(errors.layer?.message)}
                            variant='outlined'
                            label={t('select_layer')}
                            fullWidth
                            InputLabelProps={{
                              style: { color: '#424242' }
                            }}
                            disabled={action === 'update'}
                            onClick={(e) => onChangeLayerName(e.target.value)}
                          >
                            <Divider light/>
                            {layers?.map((l, index) => (
                              <MenuItem key={l.id} value={l.id}
                                        data-cy={'layerToWidget_' + index} >
                                <div style={{display:'flex', flexDirection:'row'}} >
                                  <ReactSVG
                                    src={process.env.PUBLIC_URL + '/maki-icons/' + l.customIcon}
                                    afterInjection={(svg) => {
                                      svg.getElementsByTagName('path')[0]
                                        .setAttribute('fill', l.color);
                                    }}
                                    beforeInjection={(svg) => {
                                      svg.classList.add('svg-class-name')
                                    }} style={{width: 20 , height: 20, marginLeft:3 }}/>
                                  <span style={{marginLeft:4, marginTop:-2}}>{l.name}</span>
                                </div>

                              </MenuItem>
                            ))}
                          </SelectField>
                        </MuiGridElementContainer>)}
                      <MuiGridElementContainer
                        item
                        xs={6}
                        data-cy={'widget_name'}>
                        <TextFieldCustom
                          name='name'
                          type='text'
                          margin='dense'
                          label={t('widget_name')}
                          fullWidth
                          variant='outlined'
                          style={widgetInputNameStyle}
                          error={t(errors.name?.message)}
                          color={'primary'}
                          InputLabelProps={{ style: labelInputStyle }}
                          sx={{ ...inputStyle, marginTop: 0}}
                        />
                      </MuiGridElementContainer>
                    </Grid>
                    <Grid container justifyContent='center'
                          style={{alignContent: 'flex-start'}}>
                      <MuiGridElementContainer
                        item xs={12}
                        style={{
                          marginTop: type === 'polygon' || type === 'parameters' ? 36 : 13
                        }}>
                        <TextFieldCustom
                          name='description'
                          margin='dense'
                          type='text'
                          label={t('description')}
                          multiline
                          minRows={4}
                          maxRows={4}
                          variant='outlined'
                          fullWidth
                          error={t(errors.name?.message)}
                          InputLabelProps={{ style: labelInputStyle }}
                          sx={{ ...inputAreaStyle, marginTop: '36px' }}
                        />
                      </MuiGridElementContainer>
                    </Grid>
                  </MuiGridElement>
                )}
                {activeStep === 2 && type !== 'recommendations' && (
                  <MuiGridElementContainer item xs={12}>
                    <Grid container justifyContent='center'
                          style={{alignContent: 'flex-start'}}>
                      {type === 'polygon' && (
                        <MuiGridElementContainer container spacing={3} directio={'row'}>
                          {action === 'create' ? (
                            dataset.isSuccess ? (
                              <Grid item xs={6} style={{paddingBottom: 0, display:'flex'}}>
                                <SelectField
                                  name="datasetName"
                                  variant="outlined"
                                  label={t('polygon_dataset')}
                                  InputLabelProps={{ style: labelInputStyle }}
                                  fullWidth
                                  onClick={(e) => onChangeDataSetName(e.target.dataset.value)}
                                >
                                  <MenuItem value="">
                                    <em>{ t('none') }</em>
                                  </MenuItem>
                                  <Divider light value=" "/>
                                  {dataset.data?.map((d, index) => (
                                    <MenuItem key={d._id}
                                              value={d.cartoName}
                                              data-cy={'layerToWidget_' + index}>
                                      {d.prosperiaName
                                        .split('_')
                                        .map((c) => c.charAt(0).toUpperCase() + c.slice(1))
                                        .join(' ')}
                                    </MenuItem>
                                  ))}
                                </SelectField>
                                <TypographyWithInfoIconTooltip
                                customBtnStyles={{marginLeft:'8px', marginTop:'15px'}}
                                >
                                  {t('polygon_dataset_info')}
                                </TypographyWithInfoIconTooltip>
                              </Grid>
                            ) : (
                              <CustomSkeleton className={selectSkeleton}/>
                            )
                          ) : (
                            <Grid item xs={6} style={{paddingBottom: 0}}>
                              <TextFieldCustom
                                name='datasetName'
                                variant='outlined'
                                label={t('polygon_dataset')}
                                fullWidth
                                disabled
                                value={selectDataset?.[0]?.prosperiaName
                                  .split('_')
                                  .map((c) => c.charAt(0).toUpperCase() + c.slice(1))
                                  .join(' ')}
                              >
                              </TextFieldCustom>
                            </Grid>
                          )}
                          {action === 'create' ? (
                              dataset.isSuccess ? (
                                <Grid item xs={6} style={{paddingBottom: 0, display:'flex'}}>
                                  <SelectField
                                    name="circuchroneTable"
                                    variant="outlined"
                                    label={t('auxiliar_dataset')}
                                    fullWidth
                                    InputLabelProps={{ style: labelInputStyle }}
                                    onClick={(e) => onChangeDataSetAuxName(e.target.value)}
                                  >
                                    <MenuItem value="">
                                    <em>{ t('none') }</em>
                                    </MenuItem>
                                    <Divider light value=" "/>
                                    {dataset.data?.map((d, index) => (
                                      <MenuItem key={d._id}
                                                value={d.cartoName}
                                                data-cy={'layerToWidget_' + index}>
                                        {d.prosperiaName
                                          .split('_')
                                          .map((c) => c.charAt(0).toUpperCase() + c.slice(1))
                                          .join(' ')}
                                      </MenuItem>
                                    ))}
                                  </SelectField>
                                  <TypographyWithInfoIconTooltip
                                    customBtnStyles={{marginLeft:'8px', marginTop:'15px'}}
                                  >
                                    {t('polygon_auxiliar_info')}
                                  </TypographyWithInfoIconTooltip>
                                </Grid>
                              ) : (
                                <CustomSkeleton className={selectSkeleton}/>
                              )
                            ) : action === 'update' && dataset.isSuccess ? (
                              <Grid item xs={6} style={{paddingBottom: 0}}>
                                <TextFieldCustom
                                  name='datasetName'
                                  variant='outlined'
                                  label={t('polygon_dataset')}
                                  fullWidth
                                  disabled
                                  value={circuchroneTable?.[0]?.prosperiaName
                                    .split('_')
                                    .map((c) => c.charAt(0).toUpperCase() + c.slice(1))
                                    .join(' ')}
                                >
                                </TextFieldCustom>
                              </Grid>
                            ) : null
                            }
                          {selectDataset && (
                            <>
                              <Grid item xs={4}>
                                <MuiTypographyLimit variant='caption'>
                                  {t('polygon_limit')}
                                </MuiTypographyLimit>
                                <MuiSlider
                                  name='limit'
                                  defaultValue={500}
                                  step={1}
                                  min={1}
                                  max={500}
                                  disabled={action === 'update'}
                                  value={action === 'update' ? widget.polygonLimit
                                                             : polygonLimitValue}
                                  onChange={handleChangePolygonLimit}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  name='limit'
                                  type="number"
                                  value={action === 'update' ? widget.polygonLimit
                                                             : polygonLimitValue}
                                  onChange={handleChangeInputLimit}
                                  inputProps={{
                                    step: 1,
                                    min: 1,
                                    max: 500,
                                  }}
                                  disabled={action === 'update'}
                                  style={inputLimitStyle}
                                />
                                <IconButton style={iconButtonStyle}>
                                  <InfoOutlined fontSize='small'/>
                                </IconButton>
                              </Grid>
                            </>
                          )}
                          <Grid item xs={12}>
                            {polyColumns && isSuccessPoly && (
                              <>
                                <TransferListSort
                                  items={listColumns}
                                  right={columnsListOrderPoly}
                                  setItems={handleSetItem}
                                  setRight={setColumnsListOrderPoly}
                                  setOrderSelection={setColumnsListOrderPoly}
                                  action={action}
                                  changeDetectedShow={setChangeDetectedShow}
                                  changeDetectedName={setChangeDetectedName}
                                  changeDetectedOrder={setChangeDetectedOrder}
                                  searchWidthInPixel={260}
                                  leftColumnsWidthInPixel={300}
                                  rightColumnsWidthInPixel={500}
                                  keyLabel={'polygon_column_transfer'}
                                />
                              </>
                            )}
                            {columnsListOrderPoly?.length != 0 && action == 'update' && (
                              <TransferListSort
                                items={[]}
                                right={columnsListOrderPoly}
                                setItems={handleSetItem}
                                setRight={setColumnsListOrderPoly}
                                setOrderSelection={setColumnsListOrderPoly}
                                action={action}
                                type={type}
                                changeDetectedShow={setChangeDetectedShow}
                                changeDetectedName={setChangeDetectedName}
                                changeDetectedOrder={setChangeDetectedOrder}
                                searchWidthInPixel={260}
                                leftColumnsWidthInPixel={300}
                                rightColumnsWidthInPixel={500}
                                keyLabel={'polygon_column_transfer'}
                              />
                            )}
                          </Grid>
                        </MuiGridElementContainer>
                      )}

                      {type !== 'polygon' && type !== 'recommendations' && type !== 'parameters' && (
                        <Grid container spacing={8}>
                            <MuiGridFormula item xs={12}>
                              <MuiTypographyCommon variant='subtitle1'>
                                {t('enter_expression')}
                              </MuiTypographyCommon>
                              <IconButton
                                aria-owns={openPopover ? 'mouse-over-popover' : undefined}
                                aria-haspopup="true"
                                onClick={handlePopoverOpen}
                              >
                                <InfoOutlinedIcon fontSize='small' />
                              </IconButton>
                              <CalculatorLegend
                                open={openPopover}
                                anchorEl={anchorEl}
                                setAnchorEl={setAnchorEl}
                              />
                              <MuiSwitchField
                                translation={t}
                                inputProps={{'aria-label': 'controlled'}}
                                name='calculator'
                                calculator={calculator}
                                color='secondary'
                              />
                            </MuiGridFormula>
                        </Grid>)}
                      {type === 'parameters' && (
                        <Grid container justifyContent='flex-start' >
                          <MuiTypographyCommon variant='subtitle1'>
                            {t('enter_parameters')}
                          </MuiTypographyCommon>
                        </Grid>
                      )}
                      <MuiContainerLegendItems container >
                        <Grid item container style={{marginTop: 8}} >
                          {!calculator && type !== 'list' && type !== 'parameters' && type !== 'statistics' && type !== 'polygon' && type !== 'recommendations' ? (
                            <>
                              {isSuccess && watch('layer') ? (
                                <MuiContainerSpecificInfo>
                                  { type !=='histogram' && (
                                    <MuiTypographyInfo variant='subtitle1'>
                                      {t('specific_information')}
                                    </MuiTypographyInfo>
                                  )}
                                  <MuiCustomSelectField
                                    type={type}
                                    name='datasetColumn'
                                    error={t(errors.datasetColumn?.message)}
                                    variant='outlined'
                                    label={t('select_widget_colum')}
                                    disabled={!watch('widget') && !watch('layer')}
                                    style={{width: '50%'}}
                                    InputLabelProps={{
                                      style: { color: '#424242' }
                                    }}
                                    value={ datasetColumn || '' }
                                  >
                                    <MenuItem value={' '} disabled/>
                                    {columns?.map((c, index) => (
                                      <MenuItem key={c} value={c}
                                                data-cy={'datasetColumn_' + index}>
                                        {c}
                                      </MenuItem>
                                    ))}
                                  </MuiCustomSelectField>
                                </MuiContainerSpecificInfo>
                              ) : (
                                <CustomSkeleton className={selectSkeleton}/>
                              )}
                            </>
                          ) : (
                            <>
                              {calculator && isSuccess && watch('layer') && (
                                <Grid item md={11}>
                                  {type !== 'category' ? (
                                      <FormulaGenerator
                                        methodsForFormula={[methods, makeid, setFormulaWithAlias, setColumnListOrderFormula]}
                                        variablesForFormula={[[...columns,...(definedParametersCatalog?.map(p => p.propName) || [])], action, calculator, errors,
                                          datasetColumn, widget, formulaWithAlias, type, alias, columnsListOrder, type
                                        ]}
                                        setColumnListOrder={setColumnListOrder}
                                        setColumnListOrderStats={setColumnListOrderStats}
                                        setOpenToast={setOpenToast}
                                        setSeverity={setSeverity}
                                        setMessageToast={setMessageToast}
                                        setValidFormula={setValidFormula}
                                        formulaErrors={formulaErrors}
                                        setFormulaErrors={setFormulaErrors}
                                        setIsFormulaValidate={setIsFormulaValidate}
                                        formula={formula}
                                        setFormula={setFormula}
                                        tableName={datasetName}
                                        columnsOrderStatistics={columnsListOrderStats || []}
                                        columnsList={columnsList}
                                      />
                                    ) :
                                    <CaseGenerator
                                      setDisabledContinue={setDisabledContinue}
                                      setActiveStep={setActiveStep}
                                      goCheckCaseStructure={goCheckCaseStructure}
                                      setValidatingStructureCase={setValidatingStructureCase}
                                      setColumnListOrder={setColumnListOrder}
                                      methodsForFormula={[methods, makeid, setFormulaWithAlias, setColumnListOrderFormula, setCaseCategories]}
                                      variablesForFormula={[[...columns,...(definedParametersCatalog?.map(p => p.propName) || [])], action, calculator, errors,
                                        datasetColumn, widget, formulaWithAlias, type, alias, columnsListOrder
                                        , caseCategories, isValid
                                      ]}
                                      setOpenToast={setOpenToast}
                                      setSeverity={setSeverity}
                                      setMessageToast={setMessageToast}
                                      setValidFormula={setValidFormula}
                                      tableName={datasetName}
                                      setIsCaseGenerationValid={setIsCaseGenerationValid}
                                      isCaseGenerationValid={isCaseGenerationValid}
                                    />
                                  }
                                </Grid>
                              )}
                            </>
                          )}

                        </Grid>
                        <Grid container justifyContent='flex-end' style={{marginTop: 8}}>
                          {type === 'histogram' && !calculator && (
                            <Histogram
                              gradient={gradient}
                              setGradient={setGradient}
                              selectedGradientId={widget?.params.palette_id}
                              setValue={setValue}
                              palette_reverse={palette_reverse}
                              setPaletteReverse={setPaletteReverse}
                            />
                          )}
                          {type === 'category' && !calculator && (
                            <>
                              <Grid item xs={12}>
                                <MuiTypographyVisuality variant='subtitle1'>
                                  {t('visuality')}
                                </MuiTypographyVisuality>
                                <MuiTypographySortBy variant='caption'>
                                  {t('sort_by')}
                                </MuiTypographySortBy>
                                <MuiBoxToggle>
                                  <MuiToggleButtonGroup
                                    name='order'
                                    color="primary"
                                    value={orderByValue}
                                    exclusive
                                    onChange={handleChangeOrderBy}
                                    aria-label="Platform"
                                  >
                                    <ToggleButton value="alphabetical">
                                      <MuiTypographyToggle variant='caption'>
                                        {t('alphabetical')}
                                      </MuiTypographyToggle>
                                    </ToggleButton>
                                    <ToggleButton value="ranking">
                                      <MuiTypographyToggle variant='caption'>
                                        {t('ranking')}
                                      </MuiTypographyToggle>
                                      </ToggleButton>
                                    <ToggleButton value="fixed">
                                      <MuiTypographyToggle variant='caption'>
                                        {t('fixed')}
                                      </MuiTypographyToggle>
                                      </ToggleButton>
                                  </MuiToggleButtonGroup>
                                </MuiBoxToggle>
                              </Grid>
                              <Category
                                watch={watch}
                                errors={errors}
                                order={order}
                                setOrder={setCategoryOrder}
                                gradient={gradient}
                                setValue={setValue}
                                setGradient={setGradient}
                                selectedGradientId={widget?.params.palette_id}
                                palette_reverse={palette_reverse}
                                setPaletteReverse={setPaletteReverse}
                                colorInMap={colorInMap}
                                setColorInMap={setColorInMap}
                                register={register}
                              />
                              {watch('colorType') === 'by_value' && fieldsCategory.length > 0 && (
                                <Grid item xs={4} style={{marginTop:'-26.5rem'}} >
                                  <div>
                                    <MuiTypographyCategoryList variant='subtitle1'>
                                      {t('category_list')}
                                    </MuiTypographyCategoryList>
                                  </div>
                                  <MuiGridCategoryContainer  container component={Paper}>
                                  {fieldsCategory.map((field, i) => (
                                    <MuiGridCategory item container >
                                      <Grid item key={field.value} xs={10} justifyContent='flex-end' >
                                        <MuiTypographyCategory variant='body1' noWrap>
                                          {field.value}
                                        </MuiTypographyCategory>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <CustomInputColorGradient
                                          defaultValue={field.color}
                                          name={`customCategoryColors[${i}]color`}
                                          {...register(`customCategoryColors.${i}.color`)}
                                        />
                                      </Grid>
                                    </MuiGridCategory>
                                  ))}
                                  </MuiGridCategoryContainer>
                                </Grid>
                              )}
                            </>
                          )}
                          {type === 'list' && !calculator && isSuccess && selectedLayer && columns && (
                            <>
                              <List
                                columns={columnsListOrder || []}
                                alias={alias}
                                formula={formulaWithAlias}
                                setNewOrder={setColumnListOrder}
                                realColumns={columnsList}
                              />
                              <MuiCalculatorDisabled container item xs={12}>
                                <ButtonPanel disabled />
                              </MuiCalculatorDisabled>
                            </>
                          )}
                          {type === 'statistics' && !calculator && isSuccess && selectedLayer && columns && (
                            <>
                              <Stat
                                columns={columnsListOrderStats || []}
                                setNewOrder={setColumnListOrderStats}
                                alias={alias}
                                formula={formulaWithAlias}
                                realColumns={columnsList}
                              />
                              <MuiCalculatorDisabled container item xs={12}>
                                <ButtonPanel disabled />
                              </MuiCalculatorDisabled>
                            </>
                          )}
                          {
                            type === "parameters" && (
                              <Parameters
                                parameters={parameterCatalog}
                                updateParameters={setParameterCatalog}
                                mode={action}
                              />
                            )
                          }
                        </Grid>
                      </MuiContainerLegendItems>
                    </Grid>
                  </MuiGridElementContainer>
                )}
                {activeStep === 2 && type === 'recommendations' && (
                  <RecommendationsDemand
                    errors = {errors}
                    onChangeLayerName = { onChangeLayerName }
                    isFormulaFieldSelected = { isFormulaFieldSelected }
                    setIsFormulaFieldSelected = { setIsFormulaFieldSelected }
                    setValue = { setValue }
                    isTargetDemandsFieldSelected = { isTargetDemandsFieldSelected }
                    setIsTargetDemandsFieldSelected = { setIsTargetDemandsFieldSelected }
                    layers = { layers }
                    demandDatasetName = { demandDatasetName }
                    tdform = { tdform }
                    wform = { wform }
                    filter = { filter }
                    demandDatasetColumns = { demandDatasetColumns }
                    demandDatasetColumnsIsSuccess = { demandDatasetColumnsIsSuccess }
                    setMessageToast={setMessageToast}
                    setSeverity={setSeverity}
                  />
                )}
                {activeStep === 3 && type === 'category' && calculator &&  (
                  <MuiVisuality>
                    <MuiTypographyInfo variant='subtitle1' sx={{marginTop:'-1.5rem'}}>
                      {t('specific_information')}
                    </MuiTypographyInfo>
                    <Grid container justifyContent='flex-end'>
                      <Grid item xs={12} >
                        <MuiTypographyVisuality variant='subtitle1' sx={{marginTop:'0'}}>
                          {t('visuality')}
                        </MuiTypographyVisuality>
                        <MuiTypographySortBy variant='caption'>
                          {t('sort_by')}
                        </MuiTypographySortBy>
                        <MuiBoxToggle>
                          <MuiToggleButtonGroup
                            name='order'
                            color="primary"
                            value={orderByValue}
                            exclusive
                            onChange={handleChangeOrderBy}
                            aria-label="Platform"
                          >
                            <ToggleButton value="alphabetical">
                              <MuiTypographyToggle variant='caption'>
                                {t('alphabetical')}
                              </MuiTypographyToggle>
                            </ToggleButton>
                            <ToggleButton value="ranking">
                              <MuiTypographyToggle variant='caption'>
                                {t('ranking')}
                              </MuiTypographyToggle>
                            </ToggleButton>
                            <ToggleButton value="fixed">
                              <MuiTypographyToggle variant='caption'>
                                {t('fixed')}
                              </MuiTypographyToggle>
                            </ToggleButton>
                          </MuiToggleButtonGroup>
                        </MuiBoxToggle>
                      </Grid>
                      <Category
                        watch={watch}
                        errors={errors}
                        order={order}
                        setOrder={setCategoryOrder}
                        gradient={gradient}
                        setValue={setValue}
                        setGradient={setGradient}
                        selectedGradientId={widget?.params.palette_id}
                        palette_reverse={palette_reverse}
                        setPaletteReverse={setPaletteReverse}
                        colorInMap={colorInMap}
                        setColorInMap={setColorInMap}
                        register={register}
                      />
                      {watch('colorType') === 'by_value' && fieldsCategory.length > 0 && (
                        <Grid item xs={4} style={{marginTop:'-17.5rem'}} >
                          <div>
                            <MuiTypographyCategoryList variant='subtitle1'>
                              {t('category_list')}
                            </MuiTypographyCategoryList>
                          </div>
                          <MuiGridCategoryContainer  container component={Paper} style={{marginTop:'-3rem'}}>
                            {fieldsCategory.map((field, i) => (
                              <MuiGridCategory item container >
                                <Grid item key={field.value} xs={10} justifyContent='flex-end' >
                                  <MuiTypographyCategory variant='body1' noWrap>
                                    {field.value}
                                  </MuiTypographyCategory>
                                </Grid>
                                <Grid item xs={2}>
                                  <CustomInputColorGradient
                                    defaultValue={field.color}
                                    name={`customCategoryColors[${i}]color`}
                                    {...register(`customCategoryColors.${i}.color`)}
                                  />
                                </Grid>
                              </MuiGridCategory>
                            ))}
                          </MuiGridCategoryContainer>
                        </Grid>
                      )}
                    </Grid>
                  </MuiVisuality>
                )}
                {activeStep === 3 && type === 'histogram' && calculator &&  (
                  <MuiVisuality>
                    <Grid container justifyContent='flex-end'>
                      <Grid item xs={12} >
                        <MuiTypographyVisuality variant='subtitle1' sx={{marginTop:'0'}}>
                          {t('visuality')}
                        </MuiTypographyVisuality>
                      </Grid>
                    </Grid>
                    <Histogram
                      gradient={gradient}
                      setGradient={setGradient}
                      selectedGradientId={gradient?.id}
                      setValue={setValue}
                      palette_reverse={palette_reverse}
                      setPaletteReverse={setPaletteReverse}
                    />
                  </MuiVisuality>
                )}
                {activeStep === 3 && type === 'polygon' && (
                  <>
                    <MuiTypography variant='h6' sx={{fontFamily: 'Raleway', mb: '36px'}}> {t('polygon_visuality')}</MuiTypography>
                    <Grid container xs={9}>
                      <Grid item xs={3}>
                        <Controller
                          name="layerColor"
                          control={methods.control}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              fullWidth
                              sx={textFieldCommonStyle}
                              name="layerColor"
                              label={t('layer_color_polygon')}
                              variant="outlined"
                              value={value}
                              InputLabelProps={{ style: labelInputStyle }}
                              InputProps={{
                                style: inputColorCommonStyle,
                                readOnly: true,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <CustomColorGradientField
                                      name="layerColor"
                                      defaultValue={layerColor}
                                      style={inputColorFieldStyle}
                                      onChange={onChange}
                                    />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Typography
                                      component="span"
                                      fontFamily='Montserrat'
                                      fontWeight={400}
                                      style={adornmentColorStyle}>
                                      HEX
                                    </Typography>
                                  </InputAdornment>
                                )
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item style={{width:'135px'}}>
                        <Controller
                          name="layerOpacity"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="number"
                              label={t('layer_opacity')}
                              value={layerOpacity}
                              onChange={(e) => {
                                field.onChange(e);
                                handleLayerOpacityChange(e);
                              }}
                              inputProps={{
                                step: 0.2,
                                min: 0.2,
                                max: 1,
                              }}
                              sx={commonStyle}
                              InputLabelProps={{ style: labelInputStyle }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Typography
                                      component="span"
                                      style={adornmentPercentStyle}>
                                      %
                                    </Typography>
                                  </InputAdornment>
                                )
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Controller
                          name="layerBorderColor"
                          control={methods.control}
                          render={({ field: { value, onChange } }) => (
                            <TextField
                              fullWidth
                              sx={textFieldCommonStyle}
                              name="layerBorderColor"
                              label={t('layer_border_color')}
                              variant="outlined"
                              value={value}
                              InputLabelProps={{ style: labelInputStyle }}
                              InputProps={{
                                style: inputColorCommonStyle,
                                readOnly: true,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <CustomColorGradientField
                                      name="layerBorderColor"
                                      defaultValue={watch('layerBorderColor')}
                                      style={inputColorFieldStyle}
                                      onChange={onChange}
                                    />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Typography
                                      component="span"
                                      fontFamily='Montserrat'
                                      fontWeight={400}
                                      style={adornmentColorStyle}>
                                      HEX
                                    </Typography>
                                  </InputAdornment>
                                )
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Controller
                          name="layerBorderWidth"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="number"
                              label={t('layer_border_width')}
                              value={layerBorderWidth}
                              onChange={(e) => {
                                field.onChange(e);
                                handleBorderWidthChange(e);
                              }}
                              inputProps={{
                                step: 0.2,
                                min: 0.2,
                                max: 10,
                              }}
                              sx={commonStyle}
                              InputLabelProps={{ style: labelInputStyle }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Typography
                                      component="span"
                                      fontFamily='Montserrat'
                                      fontWeight={400}
                                      style={adornmentPercentStyle}>
                                      %
                                    </Typography>
                                  </InputAdornment>
                                )
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MuiTypographyVisuality variant='h6' sx={{mt:'48px',  mb: '36px'}}>
                          {t('column_to_display')}
                        </MuiTypographyVisuality>
                      </Grid>
                      <Grid item xs={6} style={{display:'flex'}}>
                        <SelectField
                          name="polygonDisplayCol"
                          variant="outlined"
                          label={t('columns')}
                          InputLabelProps={{ style: labelInputStyle }}
                          fullWidth
                          onChange={(e) => {methods.setValue('polygonDisplayCol', e.target.value)}}
                        >
                          <MenuItem value="">
                            <em>{t('none')}</em>
                          </MenuItem>
                          <Divider light value=" "/>
                          {columnsListOrderPoly?.map((c) => (
                            <MenuItem key={c.name}
                                      value={c.field}>
                              {c.name}
                            </MenuItem>
                          ))}
                        </SelectField>
                        <TypographyWithInfoIconTooltip
                          customBtnStyles={{marginLeft:'8px', marginTop:'15px'}}
                        >
                          {t('column_to_display_info')}
                        </TypographyWithInfoIconTooltip>
                      </Grid>
                    </Grid>
                  </>
                )}
                {activeStep === 3 && type === 'recommendations' && (
                  <RecommendationsServicePoint
                    isOfferSelected = { isOfferSelected }
                    dataset = { dataset }
                    errors = { errors }
                    layers = { layers }
                    setValue = { setValue }
                    offerCoverageRadiusHandleChange = { offerCoverageRadiusHandleChange }
                    selectedLayer = { selectedLayer }
                    isPotentialOffersDatasetSelected = { isPotentialOffersDatasetSelected }
                    currentOfferDatasetName = { currentOfferDatasetName }
                    demandDatasetColumnsIsError = { demandDatasetColumnsIsError }
                    showMessage = { showMessage }
                    isOfferCapacitySelected = { isOfferCapacitySelected }
                    isFormulaFieldSelected = { isFormulaFieldSelected }
                    potentialOffersDatasetName = {widget?.params?.potentialOffersDatasetName}
                  />

                )}
                {activeStep === 4 && type === 'recommendations' && (
                  <RecommendationsVisualization
                    recommendedPointLayerColor = { recommendedPointLayerColor }
                    isGlobal = { isGlobal }
                    allowsRegenerateServicePoint = { allowsRegenerateServicePoint }
                    action = { action }
                    defaultIcon = { defaultIcon }
                    valueRecommendedIcons = { valueRecommendedIcons }
                    handleInputIcon = { handleInputIcon }
                    progress = { progress }
                    setValue = { setValue }
                    isFormulaFieldSelected = { isFormulaFieldSelected }
                    isOfferSelected = { isOfferSelected }
                    isCostVisible = { isCostVisible }
                    isOfferCapacityVisible = { isOfferCapacityVisible }
                    isExpansionCapacityVisible = { isExpansionCapacityVisible }
                    isExpansionCostVisible = { isExpansionCostVisible }
                  />
                )}
                {activeStep === 5 && type === 'recommendations' && (
                  <RecommendationsGlossary />
                )}
              </Grid>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <FormProvider {...methods}>
      <form style={{width: '100%'}}>
        <Modal
          open={open}
          onClose={onClose}
          title={action === 'update' ? `${t('update')} ${widget?.name}` : `${t('new_widget')}`}
          actions={actions}
          widthInPixels={1027}
          heightInPixels={794}
          isWidgetForm={true}
        >
          {widgetForm}
        </Modal>
      </form>
    </FormProvider>
  );
};

export default WidgetForm;
